import { push } from 'connected-react-router';
import {
  LOGIN_ERRORED,
  LOGIN_REQUESTED,
  LOGIN_SUCCESSFULL,
  LOGOUT_REQUESTED,
  LOGIN_FACEBOOK_REQUESTED,
  LOGIN_FACEBOOK_SUCCESSFULL,
  LOGIN_FACEBOOK_ERRORED,
  LOGIN_GOOGLE_REQUESTED,
  LOGIN_GOOGLE_SUCCESSFULL,
  LOGIN_GOOGLE_ERRORED,
  REGISTER_REQUESTED,
  REGISTER_SUCCESSFULL,
  REGISTER_ERRORED,
  CHECK_FACEBOOK_REQUESTED,
  CHECK_FACEBOOK_DUPLICATED,
  CHECK_FACEBOOK_ERRORED,
  IDENTIFIER_VERIFICATION_REQUESTED,
  IDENTIFIER_VERIFICATION_SUCCESSFULL,
  IDENTIFIER_VERIFICATION_ERRORED,
  IDENTIFIER_RESEND_REQUESTED,
  IDENTIFIER_RESEND_SUCCESSFULL,
  IDENTIFIER_RESEND_ERRORED,
  CHANGE_PHONE_REQUESTED,
  CHANGE_PHONE_SUCCESSFULL,
  CHANGE_PHONE_ERRORED,
  UPDATE_USER_REQUESTED,
  UPDATE_USER_SUCCESSFULL,
  UPDATE_USER_ERRORED,
  UPDATE_USER_MEDIA_REQUESTED,
  UPDATE_USER_MEDIA_SUCCESSFULL,
  UPDATE_USER_MEDIA_ERRORED,
  STATE_APP,
  STATE_WELCOME,
  CANCEL_REGISTRATION_ERRORED,
  CANCEL_REGISTRATION_REQUESTED,
  CANCEL_REGISTRATION_SUCCESSFULL,
  PIN_OPTIONS_REQUESTED,
  PIN_OPTIONS_RETRIEVED,
  PIN_OPTIONS_FAILED,
  PIN_LOGIN_REQUESTED,
  PIN_LOGIN_SUCCESSFULL,
  PIN_LOGIN_ERRORED,
  LOGIN_PIN_REQUESTED,
  LOGIN_PIN_SUCCESSFULL,
  LOGIN_PIN_ERRORED,
  LOGIN_DISABLED_SUCCESSFULL,
  PENDING_ID_STORED,
  PIN_VOICE_CALL_REQUESTED,
  PIN_VOICE_CALL_SUCCESSFUL,
  PIN_VOICE_CALL_FAILED,
  PERSONAL_INFO_POPUP_CLOSED,
  CHECK_MERCADOPAGO_REQUESTED,
  CHECK_MERCADOPAGO_SUCCESSFULL,
  CHECK_MERCADOPAGO_ERRORED,
  DOWNLOAD_APP_BANNER_CLOSED
} from './types';

import { setAppState } from './app';
import { unregisterToken } from './communication';
import { postUTM } from './user';
import AuthRestClient from '../../http/client/Authentication';
import UserRestClient from '../../http/client/Users';
import store from '../store';

export const login = (username, password) => {
  const loginRequested = () => ({
    type: LOGIN_REQUESTED
  });

  const loginSuccessfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_SUCCESSFULL,
      token,
      user: data
    };
  };

  const loginDisabledSuccessfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_DISABLED_SUCCESSFULL,
      token,
      user: data
    };
  };

  const loginErrored = error => ({
    type: LOGIN_ERRORED,
    error
  });

  return dispatch => {
    dispatch(loginRequested());

    return AuthRestClient.login(username, password)
      .then(response => {
        if (
          (Array.isArray(response.data.data.roles) &&
            response.data.data.roles.includes('ROLE_DISABLED')) ||
          (response.data.data.roles &&
            Object.keys(response.data.data.roles).find(
              r => response.data.data.roles[r] === 'ROLE_DISABLED'
            ))
        ) {
          dispatch(loginDisabledSuccessfull(response));
        } else if (
          Array.isArray(response.data.data.roles) &&
          response.data.data.roles.includes('ROLE_SHOWROOM_PENDING') &&
          response.data.data.showroom_valid === false
        ) {
          dispatch(loginSuccessfull(response));
          dispatch(setAppState(STATE_WELCOME));
          dispatch(push('/configurar-perfil'));
          return;
        } else {
          dispatch(loginSuccessfull(response));
          dispatch(setAppState(STATE_APP));
          const userReducer = store.getState().user;
          if (userReducer.localStats && userReducer.localStats.utm !== null) {
            dispatch(postUTM(userReducer.localStats.utm));
          }
          dispatch(push('/login-exitoso'));
        }
      })
      .catch(err => {
        dispatch(loginErrored(err.response));
      });
  };
};

export const facebookLogin = facebookToken => {
  const requested = () => ({ type: LOGIN_FACEBOOK_REQUESTED });
  const successfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_FACEBOOK_SUCCESSFULL,
      user: data,
      token
    };
  };
  const loginDisabledSuccessfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_DISABLED_SUCCESSFULL,
      token,
      user: data
    };
  };
  const errored = error => ({ type: LOGIN_FACEBOOK_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return AuthRestClient.facebookLogin(facebookToken)
      .then(response => {
        if (
          (Array.isArray(response.data.data.roles) &&
            response.data.data.roles.includes('ROLE_DISABLED')) ||
          (response.data.data.roles &&
            Object.keys(response.data.data.roles).find(
              r => response.data.data.roles[r] === 'ROLE_DISABLED'
            ))
        ) {
          dispatch(loginDisabledSuccessfull(response));
        } else if (
          Array.isArray(response.data.data.roles) &&
          response.data.data.roles.includes('ROLE_SHOWROOM_PENDING') &&
          response.data.data.showroom_valid === false
        ) {
          dispatch(successfull(response));
          dispatch(setAppState(STATE_WELCOME));
          dispatch(push('/configurar-perfil'));
          return;
        } else {
          dispatch(successfull(response));
          dispatch(setAppState(STATE_APP));
          const userReducer = store.getState().user;
          if (userReducer.localStats && userReducer.localStats.utm !== null) {
            dispatch(postUTM(userReducer.localStats.utm));
          }
          dispatch(push('/'));
        }
      })
      .catch(error => {
        dispatch(errored(error.response));
      });
  };
};

export const googleLogin = googleToken => {
  const requested = () => ({ type: LOGIN_GOOGLE_REQUESTED });
  const successfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_GOOGLE_SUCCESSFULL,
      user: data,
      token
    };
  };
  const loginDisabledSuccessfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_DISABLED_SUCCESSFULL,
      token,
      user: data
    };
  };
  const errored = error => ({ type: LOGIN_GOOGLE_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return AuthRestClient.googleLogin(googleToken)
      .then(response => {
        if (
          (Array.isArray(response.data.data.roles) &&
            response.data.data.roles.includes('ROLE_DISABLED')) ||
          (response.data.data.roles &&
            Object.keys(response.data.data.roles).find(
              r => response.data.data.roles[r] === 'ROLE_DISABLED'
            ))
        ) {
          dispatch(loginDisabledSuccessfull(response));
        } else if (
          Array.isArray(response.data.data.roles) &&
          response.data.data.roles.includes('ROLE_SHOWROOM_PENDING') &&
          response.data.data.showroom_valid === false
        ) {
          dispatch(successfull(response));
          dispatch(setAppState(STATE_WELCOME));
          dispatch(push('/configurar-perfil'));
          return;
        } else {
          dispatch(successfull(response));
          dispatch(setAppState(STATE_APP));
          const userReducer = store.getState().user;
          if (userReducer.localStats && userReducer.localStats.utm !== null) {
            dispatch(postUTM(userReducer.localStats.utm));
          }
          dispatch(push('/'));
        }
      })
      .catch(error => {
        dispatch(errored(error.response));
      });
  };
};

export const logout = () => {
  const logoutRequested = () => ({
    type: LOGOUT_REQUESTED
  });

  return dispatch => {
    if (store.getState().communication.token) {
      dispatch(unregisterToken()).then(() => {
        dispatch(logoutRequested());
        localStorage.setItem('logout-event', 'logout' + Math.random());
      });
    } else {
      dispatch(logoutRequested());
      localStorage.setItem('logout-event', 'logout' + Math.random());
    }
  };
};

export const register = user => {
  const { email } = user;

  const requested = () => ({ type: REGISTER_REQUESTED });

  const successfull = response => ({
    type: REGISTER_SUCCESSFULL,
    user: { ...response.data.data, email },
    token: response.data.token
  });

  const errored = response => ({
    type: REGISTER_ERRORED,
    error: response
  });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.register(user)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
          dispatch(setAppState(STATE_WELCOME));
          dispatch(push('/validar-email'));
        }
      })
      .catch(err => dispatch(errored(err.response)));
  };
};

export const cancelRegistration = () => {
  const logoutRequested = () => ({
    type: LOGOUT_REQUESTED
  });
  const requested = () => ({ type: CANCEL_REGISTRATION_REQUESTED });
  const successfull = () => ({ type: CANCEL_REGISTRATION_SUCCESSFULL });
  const errored = error => ({ type: CANCEL_REGISTRATION_ERRORED, error });

  return dispatch => {
    dispatch(requested());
    return AuthRestClient.cancelRegistration()
      .then(() => {
        dispatch(successfull());
        dispatch(logoutRequested());
        dispatch(push('/signup'));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const checkIdentifier = (type, identifier) => {
  const requested = () => ({ type: IDENTIFIER_VERIFICATION_REQUESTED });
  const successfull = () => ({
    type: IDENTIFIER_VERIFICATION_SUCCESSFULL,
    identifierType: type
  });
  const errored = error => ({
    type: IDENTIFIER_VERIFICATION_ERRORED,
    error,
    identifierType: type
  });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.identifierExist(identifier)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err.response)));
  };
};

export const checkFacebookRegistration = state => {
  const requested = () => ({ type: CHECK_FACEBOOK_REQUESTED });
  const duplicated = () => ({ type: CHECK_FACEBOOK_DUPLICATED });
  const errored = error => ({ type: CHECK_FACEBOOK_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.facebookLogin(state.facebook.token)
      .then(response => {
        if (response.status === 200) {
          dispatch(duplicated());
        }
      })
      .catch(error => {
        if (error.response.status === 404) {
          dispatch(
            push({
              pathname: '/register',
              state
            })
          );
        } else {
          dispatch(errored(error.response));
        }
      });
  };
};

export const resendPin = (identifier = null, forceSms = false) => {
  const requested = () => ({ type: IDENTIFIER_RESEND_REQUESTED });
  const successfull = () => ({ type: IDENTIFIER_RESEND_SUCCESSFULL });
  const errored = () => ({ type: IDENTIFIER_RESEND_ERRORED });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.resendPin(identifier, forceSms)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err)));
  };
};

export const changePhone = phone => {
  const requested = () => ({ type: CHANGE_PHONE_REQUESTED });
  const successfull = () => ({
    type: CHANGE_PHONE_SUCCESSFULL,
    phone
  });
  const errored = response => ({
    type: CHANGE_PHONE_ERRORED,
    error: response
  });

  return dispatch => {
    dispatch(requested());

    return UserRestClient.changePhone(phone)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response.data));
        } else {
          dispatch(successfull());
        }
      })
      .catch(err => dispatch(errored(err.response)));
  };
};

export const sendPinViaCall = identifier => {
  const requested = () => ({ type: PIN_VOICE_CALL_REQUESTED });
  const successfull = () => ({ type: PIN_VOICE_CALL_SUCCESSFUL });
  const errored = () => ({ type: PIN_VOICE_CALL_FAILED });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.sendPinViaCall(identifier)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const updateUser = user => {
  let userChanges = Object.keys(user);
  if (userChanges.includes('communicationsPreferences')) {
    userChanges = [
      ...userChanges,
      ...Object.keys(user.communicationsPreferences)
    ];
  }
  const requested = () => ({ type: UPDATE_USER_REQUESTED, userChanges });
  const successfull = response => ({
    type: UPDATE_USER_SUCCESSFULL,
    user: response.data,
    userChanges
  });
  const errored = error => ({
    type: UPDATE_USER_ERRORED,
    error,
    userChanges
  });

  return dispatch => {
    dispatch(requested());

    return UserRestClient.updateUser(user)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response.data));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(err => dispatch(errored(err.response)));
  };
};

export const updateMedia = image => {
  const requested = () => ({ type: UPDATE_USER_MEDIA_REQUESTED });
  const successfull = response => ({
    type: UPDATE_USER_MEDIA_SUCCESSFULL,
    user: response.data
  });
  const errored = error => ({ type: UPDATE_USER_MEDIA_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return UserRestClient.updateMedia(image)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getPinOptions = identifier => {
  const requested = () => ({ type: PIN_OPTIONS_REQUESTED });
  const successfull = response => ({
    type: PIN_OPTIONS_RETRIEVED,
    options: response.data
  });
  const errored = error => ({ type: PIN_OPTIONS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.pinOptions(identifier)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const requestLoginPin = (identifier, channel) => {
  const requested = () => ({ type: PIN_LOGIN_REQUESTED });
  const successfull = () => ({ type: PIN_LOGIN_SUCCESSFULL });
  const errored = error => ({ type: PIN_LOGIN_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.requestLoginPin(identifier, channel)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const pinLogin = (identifier, pin) => {
  const requested = () => ({ type: LOGIN_PIN_REQUESTED });
  const successfull = response => {
    const { token, data } = response.data;
    return {
      type: LOGIN_PIN_SUCCESSFULL,
      token,
      user: data
    };
  };
  const errored = error => ({ type: LOGIN_PIN_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return AuthRestClient.pinLogin(identifier, pin)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const setPendingReview = id => {
  const stored = () => ({
    type: PENDING_ID_STORED,
    pendingId: id
  });

  return dispatch => {
    return dispatch(stored());
  };
};

export const checkMercadopago = email => {
  const requested = () => ({ type: CHECK_MERCADOPAGO_REQUESTED });
  const successfull = () => ({
    type: CHECK_MERCADOPAGO_SUCCESSFULL
  });
  const errored = error => ({
    type: CHECK_MERCADOPAGO_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return UserRestClient.checkMercadopago(email)
      .then(response => {
        if (response.data.status === 'error') {
          dispatch(errored(response.data));
        } else {
          dispatch(successfull());
        }
      })
      .catch(err => dispatch(errored(err.response)));
  };
};

export const closePersonalInfoPopUp = () => {
  const closePersonalInfoSent = () => ({
    type: PERSONAL_INFO_POPUP_CLOSED
  });

  return dispatch => {
    return dispatch(closePersonalInfoSent());
  };
};

export const closeDownloadAppBanner = () => {
  const closeDownloadAppBannerSent = () => ({
    type: DOWNLOAD_APP_BANNER_CLOSED
  });

  return dispatch => {
    return dispatch(closeDownloadAppBannerSent());
  };
};
