import React from 'react';
import { Button, useTheme } from '@material-ui/core';

function SecondaryButton({ children, ...props }) {
  const theme = useTheme();
  return (
    <Button
      variant="contained"
      style={{
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        backgroundColor: 'white'
      }}
      {...props}
    >
      {children}
    </Button>
  );
}

export default SecondaryButton;
