import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Carousel } from 'react-responsive-carousel';
import { getBanners } from '../../redux/actions/publicity';
import { useMediaQuery } from '@material-ui/core';

const Banners = () => {
  const banners = useSelector(state => state.publicity.banners);
  const isMobile = useMediaQuery('(max-width:600px)');
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBanners(isMobile));
  }, [dispatch, isMobile]);

  const onClickBanner = index => {
    if (!banners[index].url) {
      return;
    }
    window.open(banners[index].url);
  };

  return (
    <Carousel
      autoPlay
      infiniteLoop
      stopOnHover
      showThumbs={false}
      showArrows={false}
      showStatus={false}
      height={250}
      onClickItem={onClickBanner}
    >
      {banners.map(banner => (
        <div data-testid="banner" style={{ cursor: 'pointer' }} key={banner.id}>
          <img src={banner.media.original} alt={banner.url} />
        </div>
      ))}
    </Carousel>
  );
};

export default Banners;
