import { push } from 'connected-react-router';
import moment from 'moment';
import {
  PRODUCTS_QUERY_REQUESTED,
  PRODUCTS_QUERY_RETRIEVED,
  PRODUCTS_QUERY_FAILED,
  USER_PRODUCTS_REQUESTED,
  USER_PRODUCT_RETRIEVED,
  USER_PRODUCT_FAILED,
  PRODUCTS_LATEST_REQUESTED,
  PRODUCTS_LATEST_SUCCESSFULL,
  PRODUCTS_LATEST_ERRORED,
  PRODUCTS_DISCOUNT_REQUESTED,
  PRODUCTS_DISCOUNT_SUCCESSFULL,
  PRODUCTS_DISCOUNT_ERRORED,
  PRODUCTS_POPULAR_REQUESTED,
  PRODUCTS_POPULAR_SUCCESSFULL,
  PRODUCTS_POPULAR_ERRORED,
  PUBLISHING_PRODUCT_REQUESTED,
  PUBLISHING_PRODUCT_SUCCESSFULL,
  PUBLISHING_PRODUCT_FAILED,
  PRODUCT_DETAIL_REQUESTED,
  PRODUCT_DETAIL_RETRIEVED,
  PRODUCT_DETAIL_FAILED,
  PRODUCTS_ROLE_REQUESTED,
  PRODUCTS_ROLE_RETRIEVED,
  PRODUCTS_ROLE_FAILED,
  PRODUCTS_FAVOURITE_BRANDS_REQUESTED,
  PRODUCTS_FAVOURITE_BRANDS_RETRIEVED,
  PRODUCTS_FAVOURITE_BRANDS_FAILED,
  PRODUCTS_SIZES_REQUESTED,
  PRODUCTS_SIZES_RETRIEVED,
  PRODUCTS_SIZES_FAILED,
  PRODUCTS_CATEGORY_REQUESTED,
  PRODUCTS_CATEGORY_RETRIEVED,
  PRODUCTS_CATEGORY_FAILED,
  PRODUCTS_FAVOURITES_REQUESTED,
  PRODUCTS_FAVOURITES_RETRIEVED,
  PRODUCTS_FAVOURITES_FAILED,
  PRODUCTS_SUGGESTED_DRESSERS_REQUESTED,
  PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED,
  PRODUCTS_SUGGESTED_DRESSERS_FAILED,
  PRODUCTS_FOLLOWINGS_REQUESTED,
  PRODUCTS_FOLLOWINGS_RETRIEVED,
  PRODUCTS_FOLLOWINGS_FAILED,
  PRODUCTS_BRAND_REQUESTED,
  PRODUCTS_BRAND_RETRIEVED,
  PRODUCTS_BRAND_FAILED,
  REPUBLISH_REQUESTED,
  REPUBLISH_SUCCESSFULL,
  REPUBLISH_FAILED,
  SEND_OFFER_REQUESTED,
  SEND_OFFER_SUCCESSFULL,
  SEND_OFFER_ERRORED,
  MY_PRODUCTS_REQUESTED,
  MY_PRODUCTS_FAILED,
  MY_PRODUCTS_RETRIEVED,
  OFFER_PRODUCTS_REQUESTED,
  OFFER_PRODUCTS_RETRIEVED,
  OFFER_PRODUCTS_FAILED,
  PAUSE_PRODUCT_REQUESTED,
  PAUSE_PRODUCT_ERRORED,
  PAUSE_PRODUCT_SUCCESSFULL,
  RESUME_PRODUCT_ERRORED,
  RESUME_PRODUCT_REQUESTED,
  RESUME_PRODUCT_SUCCESSFULL,
  CHANGE_PRODUCT_PRICE_REQUESTED,
  CHANGE_PRODUCT_PRICE_SUCCESSFULL,
  CHANGE_PRODUCT_PRICE_ERRORED,
  CHANGE_PRODUCT_STOCK_REQUESTED,
  CHANGE_PRODUCT_STOCK_SUCCESSFULL,
  CHANGE_PRODUCT_STOCK_ERRORED,
  DELETE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_SUCCESSFULL,
  DELETE_PRODUCT_ERRORED,
  ACCEPT_OFFER_REQUESTED,
  ACCEPT_OFFER_SUCCESSFULL,
  ACCEPT_OFFER_ERRORED,
  REJECT_OFFER_REQUESTED,
  REJECT_OFFER_SUCCESSFULL,
  REJECT_OFFER_ERRORED,
  COUNTER_OFFER_REQUESTED,
  COUNTER_OFFER_SUCCESSFULL,
  COUNTER_OFFER_ERRORED,
  DROP_NOTIFICATION_REQUESTED,
  DROP_NOTIFICATION_SUCCESSFULL,
  DROP_NOTIFICATION_ERRORED,
  UPDATE_PRODUCT_REQUESTED,
  UPDATE_PRODUCT_SUCCESSFULL,
  UPDATE_PRODUCT_ERRORED,
  UPDATE_PRODUCT_IMAGES_SUCCESSFULL,
  UPDATE_PRODUCT_IMAGES_ERRORED,
  REPUBLISH_OWNED_REQUESTED,
  REPUBLISH_OWNED_SUCCESSFUL,
  REPUBLISH_OWNED_FAILED,
  CHANGE_MASSIVE_PRODUCTS_PRICE_REQUESTED,
  CHANGE_MASSIVE_PRODUCTS_PRICE_ERRORED,
  CHANGE_MASSIVE_PRODUCTS_PRICE_SUCCESSFULL,
  SEND_PRODUCT_EVENT_ANALYTICS_REQUESTED,
  SEND_PRODUCT_EVENT_ANALYTICS_SUCCESSFULL,
  SEND_PRODUCT_EVENT_ANALYTICS_ERRORED
} from './types';
import ProductRestClient from '../../http/client/Product';
import UsersRestClient from '../../http/client/Users';
import Analytics from '../../services/Analytics';

export const getProducts = (query, page, pageSize) => {
  const timestamp = moment().unix();
  const requested = () => ({ type: PRODUCTS_QUERY_REQUESTED, timestamp });
  const successfull = response => ({
    type: PRODUCTS_QUERY_RETRIEVED,
    products: response.data.items || response.data,
    facets: response.data.facets,
    total: response.data.total,
    timestamp
  });
  const errored = error => ({ type: PRODUCTS_QUERY_FAILED, error, timestamp });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts(query, page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getUserProducts = (user, query) => {
  const requested = () => ({ type: USER_PRODUCTS_REQUESTED, user });
  const successfull = response => ({
    type: USER_PRODUCT_RETRIEVED,
    products: response.data,
    user
  });
  const errored = error => ({ type: USER_PRODUCT_FAILED, error, user });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.productsFromUser(user, query)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getMyProducts = query => {
  const requested = () => ({ type: MY_PRODUCTS_REQUESTED });
  const successfull = response => ({
    type: MY_PRODUCTS_RETRIEVED,
    products: response.data
  });
  const errored = error => ({ type: MY_PRODUCTS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getMyProducts(query)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getOfferProducts = query => {
  const requested = () => ({ type: OFFER_PRODUCTS_REQUESTED });
  const successfull = response => ({
    type: OFFER_PRODUCTS_RETRIEVED,
    products: response.data
  });
  const errored = error => ({ type: OFFER_PRODUCTS_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getOfferProducts(query)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getLatestProducts = (page = 1) => {
  const requested = () => ({ type: PRODUCTS_LATEST_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_LATEST_SUCCESSFULL,
    products: response.data.items || response.data
  });
  const errored = error => ({ type: PRODUCTS_LATEST_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      sort: 'new',
      page
    })
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getDiscountProducts = (page = 1) => {
  const requested = () => ({ type: PRODUCTS_DISCOUNT_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_DISCOUNT_SUCCESSFULL,
    products: response.data.items || response.data
  });
  const errored = error => ({ type: PRODUCTS_DISCOUNT_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      promo: [
        'THREE_FOR_TWO',
        'TWO_FOR_ONE',
        'TWENTY_PERCENT',
        'THIRTY_PERCENT',
        'FORTY_PERCENT',
        'FREE_SHIPPING'
      ],
      page
    })
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getPopularProducts = () => {
  const requested = () => ({ type: PRODUCTS_POPULAR_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_POPULAR_SUCCESSFULL,
    products: response.data.items || response.data
  });
  const errored = error => ({ type: PRODUCTS_POPULAR_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      sort: 'likes',
      page: Math.floor(Math.random() * 20) + 1 // Will request a random page in the 1-20 range
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getProductsByRole = (role, sort) => {
  const requested = () => ({
    type: PRODUCTS_ROLE_REQUESTED,
    role: Array.isArray(role) ? role.join('-') : role
  });
  const successfull = response => ({
    type: PRODUCTS_ROLE_RETRIEVED,
    role: Array.isArray(role) ? role.join('-') : role,
    products: response.data.items || response.data
  });
  const errored = error => ({
    type: PRODUCTS_ROLE_FAILED,
    error,
    role: Array.isArray(role) ? role.join('-') : role
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      role: Array.isArray(role) ? role : [role],
      sort
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getFavouriteBrandsProducts = brands => {
  const requested = () => ({ type: PRODUCTS_FAVOURITE_BRANDS_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_FAVOURITE_BRANDS_RETRIEVED,
    products: response.data.items || response.data
  });
  const errored = error => ({
    type: PRODUCTS_FAVOURITE_BRANDS_FAILED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      brand: brands
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getSizesProducts = sizes => {
  const requested = () => ({ type: PRODUCTS_SIZES_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_SIZES_RETRIEVED,
    products: response.data.items || response.data
  });
  const errored = error => ({ type: PRODUCTS_SIZES_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      size: sizes
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getFavouriteProducts = (user, page, pageSize) => {
  const requested = () => ({ type: PRODUCTS_FAVOURITES_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_FAVOURITES_RETRIEVED,
    products: response.data
  });
  const errored = error => ({ type: PRODUCTS_FAVOURITES_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.getFavourites(user, page, pageSize)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getCategoryProducts = (categoryIDs, categoryType = 'category') => {
  const requested = () => ({
    type: PRODUCTS_CATEGORY_REQUESTED,
    categoryID: categoryIDs.join('-')
  });
  const successfull = response => ({
    type: PRODUCTS_CATEGORY_RETRIEVED,
    products: response.data.items || response.data,
    categoryID: categoryIDs.join('-')
  });
  const errored = error => ({
    type: PRODUCTS_CATEGORY_FAILED,
    error,
    categoryID: categoryIDs.join('-')
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProducts({
      [categoryType]: categoryIDs
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getSuggestedDresserProducts = () => {
  const requested = () => ({ type: PRODUCTS_SUGGESTED_DRESSERS_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_SUGGESTED_DRESSERS_RETRIEVED,
    products: response.data.items || response.data
  });
  const errored = error => ({
    type: PRODUCTS_SUGGESTED_DRESSERS_FAILED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return UsersRestClient.fetchRecommends()
      .then(response =>
        ProductRestClient.getProducts({
          user: response.data.items.map(user => user.id)
        })
      )
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getFollowingsProduct = followingsIDs => {
  const requested = () => ({ type: PRODUCTS_FOLLOWINGS_REQUESTED });
  const successfull = response => ({
    type: PRODUCTS_FOLLOWINGS_RETRIEVED,
    products: response.data.items || response.data
  });
  const errored = error => ({ type: PRODUCTS_FOLLOWINGS_FAILED, error });

  return dispatch => {
    dispatch(requested());
    return ProductRestClient.getProducts({
      user: followingsIDs
    })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getBrandProduct = brand => {
  const requested = () => ({ type: PRODUCTS_BRAND_REQUESTED, brand });
  const successfull = response => ({
    type: PRODUCTS_BRAND_RETRIEVED,
    brand,
    products: response.data.items || response.data
  });
  const errored = error => ({
    type: PRODUCTS_BRAND_FAILED,
    brand,
    error
  });

  return dispatch => {
    dispatch(requested());
    return ProductRestClient.getProducts({ brand: [brand] })
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const publishProduct = (product, appLayoutOptions) => {
  const requested = () => ({ type: PUBLISHING_PRODUCT_REQUESTED });
  const successfull = () => ({ type: PUBLISHING_PRODUCT_SUCCESSFULL });
  const errored = error => ({ type: PUBLISHING_PRODUCT_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.publishProduct(product)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          const publishedProduct = response.data;

          let path = `/producto/id/${publishedProduct.id}`;
          if (
            publishedProduct.sub_sub_category &&
            publishedProduct.sub_sub_category.sub_category &&
            publishedProduct.sub_sub_category.sub_category.category
          ) {
            const category = publishedProduct.sub_sub_category.sub_category.category.name
              .replace(' ', '-')
              .toLowerCase();
            const subCategory = publishedProduct.sub_sub_category.sub_category.name
              .replace(' ', '-')
              .toLowerCase();
            const subSubCategory = publishedProduct.sub_sub_category.name
              .replace(' ', '-')
              .toLowerCase();
            path = `/producto/${category}/${subCategory}/${subSubCategory}/${publishedProduct.id}`;
          }

          Analytics.newListing(response.data);
          dispatch(successfull(response));
          dispatch(
            push({
              pathname: '/home',
              state: {
                ...appLayoutOptions,
                productPath: path,
                productTitle: publishProduct.title
              }
            })
          );
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getProduct = productID => {
  const requested = () => ({ type: PRODUCT_DETAIL_REQUESTED });
  const successfull = response => ({
    type: PRODUCT_DETAIL_RETRIEVED,
    product: response.data
  });
  const errored = error => ({ type: PRODUCT_DETAIL_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.getProduct(productID)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const republish = republishProduct => {
  const requested = () => ({ type: REPUBLISH_REQUESTED, republishProduct });
  const successfull = response => ({
    type: REPUBLISH_SUCCESSFULL,
    republish: response.data,
    republishProduct
  });
  const errored = error => ({
    type: REPUBLISH_FAILED,
    error,
    republishProduct
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.republish(republishProduct)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const republishOwned = republishProductId => {
  const requested = () => ({
    type: REPUBLISH_OWNED_REQUESTED,
    republishProductId
  });
  const successfull = response => ({
    type: REPUBLISH_OWNED_SUCCESSFUL,
    republishedProduct: response.data,
    republishProductId
  });
  const errored = error => ({
    type: REPUBLISH_OWNED_FAILED,
    error,
    republishProductId
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.republishOwned(republishProductId)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const sendOffer = (product, offer) => {
  const requested = () => ({ type: SEND_OFFER_REQUESTED });
  const successfull = response => ({
    type: SEND_OFFER_SUCCESSFULL,
    offer: response.data
  });
  const errored = error => ({ type: SEND_OFFER_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.sendOffer(product, offer)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const pauseProduct = product => {
  const requested = () => ({ type: PAUSE_PRODUCT_REQUESTED, product });
  const successfull = response => ({
    type: PAUSE_PRODUCT_SUCCESSFULL,
    product: response.data
  });
  const errored = error => ({ type: PAUSE_PRODUCT_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.pause(product)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const resumeProduct = product => {
  const requested = () => ({ type: RESUME_PRODUCT_REQUESTED, product });
  const successfull = response => ({
    type: RESUME_PRODUCT_SUCCESSFULL,
    product: response.data
  });
  const errored = error => ({ type: RESUME_PRODUCT_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.resumes(product)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const changeProductPrice = (product, price) => {
  const requested = () => ({ type: CHANGE_PRODUCT_PRICE_REQUESTED, product });
  const successfull = response => ({
    type: CHANGE_PRODUCT_PRICE_SUCCESSFULL,
    product: response.data
  });
  const errored = error => ({
    type: CHANGE_PRODUCT_PRICE_ERRORED,
    error,
    product
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.changePrice(product, price)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const changeProductStock = (product, stock) => {
  const requested = () => ({ type: CHANGE_PRODUCT_STOCK_REQUESTED, product });
  const successfull = response => ({
    type: CHANGE_PRODUCT_STOCK_SUCCESSFULL,
    product: response.data
  });
  const errored = error => ({
    type: CHANGE_PRODUCT_STOCK_ERRORED,
    error,
    product
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.changeStock(product, stock)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deleteProduct = product => {
  const requested = () => ({ type: DELETE_PRODUCT_REQUESTED });
  const successfull = response => ({
    type: DELETE_PRODUCT_SUCCESSFULL,
    product
  });
  const errored = error => ({ type: DELETE_PRODUCT_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.delete(product)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const acceptOffer = product => {
  const requested = () => ({ type: ACCEPT_OFFER_REQUESTED, product });
  const successfull = () => ({
    type: ACCEPT_OFFER_SUCCESSFULL,
    product
  });
  const errored = error => ({ type: ACCEPT_OFFER_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.acceptOffer(product)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const rejectOffer = product => {
  const requested = () => ({ type: REJECT_OFFER_REQUESTED, product });
  const successfull = () => ({
    type: REJECT_OFFER_SUCCESSFULL,
    product
  });
  const errored = error => ({ type: REJECT_OFFER_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.rejectOffer(product)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const counterOffer = (product, counterOffer) => {
  const requested = () => ({ type: COUNTER_OFFER_REQUESTED, product });
  const successfull = () => ({
    type: COUNTER_OFFER_SUCCESSFULL,
    product
  });
  const errored = error => ({ type: COUNTER_OFFER_ERRORED, error, product });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.counterOffer(product, counterOffer)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const requestNotification = (product, user) => {
  const requested = () => ({
    type: DROP_NOTIFICATION_REQUESTED,
    product: product.id
  });
  const successfull = () => ({
    type: DROP_NOTIFICATION_SUCCESSFULL,
    product: product.id,
    user
  });
  const errored = error => ({
    type: DROP_NOTIFICATION_ERRORED,
    error,
    product: product.id
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.requestNotification(product.id)
      .then(() => {
        Analytics.wishlist(product);
        dispatch(successfull());
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const productUpdate = (productID, product, imagesChanged) => {
  const requested = () => ({ type: UPDATE_PRODUCT_REQUESTED });
  const successfull = () => ({ type: UPDATE_PRODUCT_SUCCESSFULL });
  const errored = error => ({ type: UPDATE_PRODUCT_ERRORED, error });

  const successfullImagesUpdate = () => ({
    type: UPDATE_PRODUCT_IMAGES_SUCCESSFULL
  });
  const erroredImagesUpdate = error => ({
    type: UPDATE_PRODUCT_IMAGES_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());
    return ProductRestClient.editProduct(productID, product)
      .then(() => {
        dispatch(successfull());
        // We know that dispatching UPDATE_PRODUCT_REQUESTED will trigger
        // a request to edit the images if it's successfull, so knowing that
        // we won't need to be notified of the imaged update requested, and
        // only inform when that's successfull or errored to give the user feedback,
        // we leave the UPDATE_PRODUCT_IMAGES_REQUESTED in types since it's
        // probable that we gonna need for a different specific action.
        if (!imagesChanged) {
          dispatch(successfullImagesUpdate());
          dispatch(
            push('/mis-ventas/mis-productos', { successfullEdit: product })
          );
          return;
        }
        return ProductRestClient.editProductImages(productID, product);
      })
      .then(() => {
        dispatch(successfullImagesUpdate());
        dispatch(
          push('/mis-ventas/mis-productos', { successfullEdit: product })
        );
      })
      .catch(error => dispatch(errored(error.response)))
      .catch(error => dispatch(erroredImagesUpdate(error.response)));
  };
};

export const changeMassiveProductPrice = (products, percentage) => {
  const requested = () => ({
    type: CHANGE_MASSIVE_PRODUCTS_PRICE_REQUESTED,
    products,
    percentage
  });
  const successful = data => ({
    type: CHANGE_MASSIVE_PRODUCTS_PRICE_SUCCESSFULL,
    successful: data.success,
    failed: data.failed
  });
  const errored = error => ({
    type: CHANGE_MASSIVE_PRODUCTS_PRICE_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());

    return ProductRestClient.changeMassiveProductPrice(products, percentage)
      .then(response => {
        dispatch(successful(response.data));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const sendProductEventAnalytics = (productId, event) => {
  const requested = () => ({
    type: SEND_PRODUCT_EVENT_ANALYTICS_REQUESTED
  });
  const successful = () => ({
    type: SEND_PRODUCT_EVENT_ANALYTICS_SUCCESSFULL
  });
  const errored = error => ({
    type: SEND_PRODUCT_EVENT_ANALYTICS_ERRORED,
    error
  });

  return dispatch => {
    dispatch(requested());

    let marketingData;
    const ga_client_id = localStorage.getItem('ga_client_id');

    if (ga_client_id) {
      marketingData = {
        id: ga_client_id,
        id_type: 'ga_client_id',
        from: 'web'
      };
    }

    return ProductRestClient.sendProductEvent(productId, event, marketingData)
      .then(response => {
        dispatch(successful(response.data));
      })
      .catch(error => dispatch(errored(error.response)));
  };
};
