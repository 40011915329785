import {
  SIZES_REQUESTED,
  SIZES_RETRIEVED,
  SIZES_FAILED,
  SIZES_ALL_REQUESTED,
  SIZES_ALL_RETRIEVED,
  SIZES_ALL_FAILED,
  CATEGORIES_REQUESTED,
  CATEGORIES_RETRIEVED,
  CATEGORIES_FAILED,
  BRANDS_REQUESTED,
  BRANDS_RETRIEVED,
  BRANDS_FAILED,
  FEATURED_BRANDS_REQUESTED,
  FEATURED_BRANDS_RETRIEVED,
  FEATURED_BRANDS_FAILED,
  SUBSUBCAT_REQUESTED,
  SUBSUBCAT_RETRIEVED,
  SUBSUBCAT_FAILED,
  COLORS_REQUESTED,
  COLORS_ERRORED,
  COLORS_SUCCESSFULL,
  TAGS_REQUESTED,
  TAGS_ERRORED,
  TAGS_SUCCESSFULL
} from './types';
import CategoriesRestClient from '../../http/client/Categories';

export const fetchSizes = () => {
  const requested = () => ({ type: SIZES_REQUESTED });
  const successfull = response => ({
    type: SIZES_RETRIEVED,
    sizes: response.data
  });
  const errored = error => ({ type: SIZES_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchSizes(false)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchAllSizes = () => {
  const requested = () => ({ type: SIZES_ALL_REQUESTED });
  const successfull = response => ({
    type: SIZES_ALL_RETRIEVED,
    sizes: response.data
  });
  const errored = error => ({ type: SIZES_ALL_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchAllSizes()
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchBrands = filter => {
  let REQUESTED_TYPE = BRANDS_REQUESTED;
  let SUCCESSFULL_TYPE = BRANDS_RETRIEVED;
  let ERROR_TYPE = BRANDS_FAILED;

  if (filter === 'popular') {
    REQUESTED_TYPE = FEATURED_BRANDS_REQUESTED;
    SUCCESSFULL_TYPE = FEATURED_BRANDS_RETRIEVED;
    ERROR_TYPE = FEATURED_BRANDS_FAILED;
  }

  const requested = () => ({
    type: REQUESTED_TYPE
  });
  const successfull = response => ({
    type: SUCCESSFULL_TYPE,
    brands: response.data
  });
  const errored = error => ({ type: ERROR_TYPE, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchBrands(filter)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchCategories = () => {
  const requested = () => ({ type: CATEGORIES_REQUESTED });
  const successfull = response => ({
    type: CATEGORIES_RETRIEVED,
    categories: response.data
  });
  const errored = error => ({ type: CATEGORIES_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchCategories()
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchSubSubCategory = category => {
  const requested = () => ({ type: SUBSUBCAT_REQUESTED });
  const successfull = response => ({
    type: SUBSUBCAT_RETRIEVED,
    category: response.data
  });
  const errored = error => ({ type: SUBSUBCAT_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchSubSubCategory(category)
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchColors = () => {
  const requested = () => ({ type: COLORS_REQUESTED });
  const successfull = response => ({
    type: COLORS_SUCCESSFULL,
    colors: response.data
  });
  const errored = error => ({ type: COLORS_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchColors()
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};

export const fetchTags = () => {
  const requested = () => ({ type: TAGS_REQUESTED });
  const successfull = response => ({
    type: TAGS_SUCCESSFULL,
    tags: response.data
  });
  const errored = error => ({ type: TAGS_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return CategoriesRestClient.fetchTags()
      .then(response => {
        if (response.status === 'error') {
          dispatch(errored(response));
        } else {
          dispatch(successfull(response));
        }
      })
      .catch(error => dispatch(errored(error.response)));
  };
};
