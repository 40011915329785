import {
  CREATE_ORDER_REQUESTED,
  CREATE_ORDER_SUCCESSFULL,
  CREATE_ORDER_ERRORED,
  DELETE_ORDER_REQUESTED,
  DELETE_ORDER_SUCCESSFULL,
  DELETE_ORDER_ERRORED,
  DELETE_ORDER_GROUP_REQUESTED,
  DELETE_ORDER_GROUP_SUCCESSFULL,
  DELETE_ORDER_GROUP_ERRORED,
  DELETE_SELLER_ORDER_REQUESTED,
  DELETE_SELLER_ORDER_SUCCESSFULL,
  DELETE_SELLER_ORDER_ERRORED,
  CONFIRM_ORDER_GROUP_REQUESTED,
  CONFIRM_ORDER_GROUP_SUCCESSFULL,
  CONFIRM_ORDER_GROUP_FAILED,
  SHIPPING_LABEL_REQUESTED,
  SHIPPING_LABEL_RETRIEVED,
  SHIPPING_LABEL_FAILED,
  SELLER_ORDERS_REQUESTED,
  SELLER_ORDERS_RETRIEVED,
  SELLER_ORDERS_FAILED,
  BUYER_ORDERS_REQUESTED,
  BUYER_ORDERS_RETRIEVED,
  BUYER_ORDERS_FAILED,
  ORDER_REQUESTED,
  ORDER_RETRIEVED,
  ORDER_FAILED,
  CREATE_SHIPPING_LABEL_REQUESTED,
  CREATE_SHIPPING_LABEL_SUCCESSFULL,
  CREATE_SHIPPING_LABEL_ERRORED,
  TRACKING_REQUESTED,
  TRACKING_RETRIEVED,
  TRACKING_ERRORED,
  REFUND_REQUESTED,
  REFUND_SUCCESSFULL,
  REFUND_ERRORED,
  CHANGE_CANCELLATION_REQUESTED,
  CHANGE_CANCELLATION_SUCCESSFUL,
  CHANGE_CANCELLATION_FAILED,
  CREATE_CHANGE_SHIPPING_REQUESTED,
  CREATE_CHANGE_SHIPPING_SUCCESSFUL,
  CREATE_CHANGE_SHIPPING_FAILED
} from './types';
import OrderRestClient from '../../http/client/Order';

export const createOrder = order => {
  const requested = () => ({ type: CREATE_ORDER_REQUESTED });
  const successfull = response => ({
    type: CREATE_ORDER_SUCCESSFULL,
    order: response.data
  });
  const errored = error => ({ type: CREATE_ORDER_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.createOrder(order)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deleteOrder = order => {
  const requested = () => ({
    type: DELETE_ORDER_REQUESTED,
    deletingOrder: order.id
  });
  const successfull = () => ({
    type: DELETE_ORDER_SUCCESSFULL,
    deletingOrder: order.id
  });
  const errored = error => ({
    type: DELETE_ORDER_ERRORED,
    error,
    deletingOrder: order.id
  });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.deleteOrder(order.id)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deleteOrderGroup = order => {
  const requested = () => ({
    type: DELETE_ORDER_GROUP_REQUESTED,
    deletingOrder: order.id
  });
  const successfull = () => ({
    type: DELETE_ORDER_GROUP_SUCCESSFULL,
    deletingOrder: order.id
  });
  const errored = error => ({
    type: DELETE_ORDER_GROUP_ERRORED,
    error,
    deletingOrder: order.id
  });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.deleteOrderGroup(order.id)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const deleteSellerOrder = order => {
  const requested = () => ({
    type: DELETE_SELLER_ORDER_REQUESTED,
    deletingOrder: order
  });
  const successfull = () => ({
    type: DELETE_SELLER_ORDER_SUCCESSFULL,
    deletingOrder: order
  });
  const errored = error => ({
    type: DELETE_SELLER_ORDER_ERRORED,
    error,
    deletingOrder: order
  });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.deleteSellerOrder(order)
      .then(() => dispatch(successfull()))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const confirmOrderGroup = (order, orderConfirmType) => {
  const requested = () => ({ type: CONFIRM_ORDER_GROUP_REQUESTED });
  const successfull = response => ({
    type: CONFIRM_ORDER_GROUP_SUCCESSFULL,
    order: response.data
  });
  const errored = error => ({ type: CONFIRM_ORDER_GROUP_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.confirmOrderGroup(order, orderConfirmType)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getShippingLabel = (order, refund = false) => {
  const requested = () => ({ type: SHIPPING_LABEL_REQUESTED });
  const successfull = response => ({
    type: SHIPPING_LABEL_RETRIEVED,
    label: response.data
  });
  const errored = error => ({ type: SHIPPING_LABEL_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getShippingLabel(order, refund)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getSellerOrders = (
  status,
  sort,
  search = '',
  offset,
  pageSize
) => {
  const requested = () => ({ type: SELLER_ORDERS_REQUESTED, status });
  const successfull = response => ({
    type: SELLER_ORDERS_RETRIEVED,
    sellerOrders: response.data,
    status
  });
  const errored = error => ({ type: SELLER_ORDERS_FAILED, error, status });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getSellerOrders(
      status,
      sort,
      search,
      offset,
      pageSize
    )
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getBuyerOrders = (status, sort, search = '', offset, pageSize) => {
  const requested = () => ({ type: BUYER_ORDERS_REQUESTED, status });
  const successfull = response => ({
    type: BUYER_ORDERS_RETRIEVED,
    buyerOrders: response.data,
    status
  });
  const errored = error => ({ type: BUYER_ORDERS_FAILED, error, status });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getBuyerOrders(
      status,
      sort,
      search,
      offset,
      pageSize
    )
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getOrder = order => {
  const requested = () => ({ type: ORDER_REQUESTED });
  const successfull = response => ({
    type: ORDER_RETRIEVED,
    order: response.data
  });
  const errored = error => ({ type: ORDER_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getOrder(order)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getOrderGroup = order => {
  const requested = () => ({ type: ORDER_REQUESTED });
  const successfull = response => ({
    type: ORDER_RETRIEVED,
    order: response.data
  });
  const errored = error => ({ type: ORDER_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getOrderGroup(order)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createShippingLabel = (
  order,
  shippingAddress,
  isChangingStore
) => {
  const requested = () => ({ type: CREATE_SHIPPING_LABEL_REQUESTED });
  const successfull = response => ({
    type: CREATE_SHIPPING_LABEL_SUCCESSFULL,
    label: response.data
  });
  const errored = error => ({ type: CREATE_SHIPPING_LABEL_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.createShippingLabel(
      order,
      shippingAddress,
      isChangingStore
    )
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const getTracking = order => {
  const requested = () => ({ type: TRACKING_REQUESTED });
  const successfull = response => ({
    type: TRACKING_RETRIEVED,
    tracking: response.data
  });
  const errored = error => ({ type: TRACKING_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.getTracking(order)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const refund = (order, products) => {
  const requested = () => ({ type: REFUND_REQUESTED });
  const successfull = response => ({
    type: REFUND_SUCCESSFULL,
    order: response.data
  });
  const errored = error => ({ type: REFUND_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.refund(order, products)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const createChangeShipping = (order, store) => {
  const requested = () => ({ type: CREATE_CHANGE_SHIPPING_REQUESTED });
  const successfull = response => ({
    type: CREATE_CHANGE_SHIPPING_SUCCESSFUL,
    shipping: response.data
  });
  const errored = error => ({ type: CREATE_CHANGE_SHIPPING_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.createChangeShipping(order, store)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};

export const cancelChange = order => {
  const requested = () => ({ type: CHANGE_CANCELLATION_REQUESTED });
  const successfull = response => ({
    type: CHANGE_CANCELLATION_SUCCESSFUL,
    order: response.data
  });
  const errored = error => ({ type: CHANGE_CANCELLATION_FAILED, error });

  return dispatch => {
    dispatch(requested());

    return OrderRestClient.cancelChange(order)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};
