import {
  CREATE_DEVICES_REQUESTED,
  CREATE_DEVICES_SUCCESSFULL,
  CREATE_DEVICES_ERRORED
} from './types';
import DevicesClient from '../../http/client/Devices';

export const saveDevice = device => {
  const requested = () => ({ type: CREATE_DEVICES_REQUESTED });
  const successfull = response => ({
    type: CREATE_DEVICES_SUCCESSFULL,
    info: response.data
  });
  const errored = error => ({ type: CREATE_DEVICES_ERRORED, error });

  return dispatch => {
    dispatch(requested());

    return DevicesClient.createDevice(device)
      .then(response => dispatch(successfull(response)))
      .catch(error => dispatch(errored(error.response)));
  };
};
