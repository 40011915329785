import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { closeDownloadAppBanner } from '../../redux/actions/auth';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  footer: {
    position: 'sticky',
    zIndex: 99999, // hack because some objects are appearing over it.
    padding: theme.spacing(2),
    bottom: 0,
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    },
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.primary.background,
    '& > hr': {
      alignSelf: 'stretch',
      height: 'auto'
    },
    boxShadow: '2px 0px 10px 1px rgba(0, 0, 0, 0.2)'
  },
  footerSection: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 4, 0, 4),
    alignItems: 'center',
    textAlign: 'center',
    '& > button': {
      width: '100%'
    },
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerTitle: {
    fontWeight: 500
  },
  socialAvatar: {
    color: theme.palette.secondary.light,
    backgroundColor: '#fff'
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main
  },
  faq: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  footerButton: {
    width: '100%',
    '& > .MuiButton-label': {
      padding: 0
    }
  },
  legalFooter: {
    backgroundColor: '#000',
    color: '#fff',
    width: '100%',
    padding: '8px 125px 8px 125px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    }
  },
  legalLogos: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1)
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(2)
      }
    }
  },
  faqLink: {
    color: '#414042',
    textDecoration: 'none',
    display: 'block'
  }
}));

const DownloadAppBanner = ({ isLoggedIn, ...props }) => {
  const classes = useStyles(props);
  const userAgent = window.navigator.userAgent;
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const downloadAppBannerExpireDate = useSelector(
    state => state.auth.downloadAppBannerExpireDate
  );

  const isIOS = () => /iPad|iPhone|iPod/.test(userAgent);

  const downloadLink = isIOS()
    ? 'https://apps.apple.com/app/id1110486509'
    : 'https://play.google.com/store/apps/details?id=com.renovatuvestidor.rtvapp';

  const onClose = () => {
    if (isLoggedIn) {
      // Set the timestamp on close only if user is not logged in. Otherwise set a timestamp to check after render again.
      dispatch(closeDownloadAppBanner());
    }
    setOpen(false);
  };

  useEffect(() => {
    // Check if downloadAppBannerExpireDate is greater than today's unix time or null
    const shouldDisplayBanner =
      !downloadAppBannerExpireDate ||
      downloadAppBannerExpireDate < moment().format('X');

    setOpen(shouldDisplayBanner);
  }, [downloadAppBannerExpireDate]);

  return (
    <>
      {open && (
        <div className={classes.footer}>
          <div className={classes.footerSection} style={{ margin: 0 }}>
            <div className={classes.socialFooter}>
              <Typography className={classes.footerTitle}>
                Bajate la app y comprá más fácil y rápido!
              </Typography>
            </div>
            <Button
              className={classes.footerButton}
              style={{
                backgroundColor: '#fff',
                color: '#403f41'
              }}
              variant="contained"
              onClick={onClose}
            >
              AHORA NO
            </Button>
            <Button
              className={classes.footerButton}
              color="primary"
              variant="contained"
              component="a"
              target="_blank"
              href={downloadLink}
            >
              DESCARGAR
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default DownloadAppBanner;
