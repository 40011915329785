import AbstractRestClient from '../AbstractRestClient';

class PublicityRestClient extends AbstractRestClient {
  getBanners(mobile) {
    let onlyParam = 'onlyWeb=true';
    if (mobile) {
      onlyParam = 'onlyResponsive=true';
    }
    return this.instance.get(
      `/api/es_ar/publicity/banners?onlyActive=true&${onlyParam}`
    );
  }
}

export default new PublicityRestClient();
