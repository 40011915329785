import { OPEN_BLOCKED_USER_ALERT } from '../actions/types';

const defaultState = {
  openBlockedUserAlert: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case OPEN_BLOCKED_USER_ALERT: {
      return {
        ...state,
        status: OPEN_BLOCKED_USER_ALERT,
        openBlockedUserAlert: action.payload
      };
    }
    default:
      return state;
  }
};
