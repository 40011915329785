import React from 'react';
import RTVDialog from '../commons/dialogs/RTVDialog';
import { useDispatch, useSelector } from 'react-redux';
import { showBlockedUserErrorAlert } from '../redux/actions/common';

export const ErrorsAlertProvider = ({ children }) => {
  const distpach = useDispatch();
  const openBlockedUserAlert = useSelector(
    state => state.commons.openBlockedUserAlert
  );

  return (
    <>
      {children}
      <RTVDialog
        open={openBlockedUserAlert}
        onClose={() => distpach(showBlockedUserErrorAlert(false))}
        title="UPS! no podes realizar esta acción."
        message="La vendedora bloqueó a tu usuario para que no puedas interactuar con sus productos."
      />
    </>
  );
};
