import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Button, Divider } from '@material-ui/core';
import numberWithCommas from '../../utils/numberWithCommas';
import confettiGif from '../../images/confetti.gif';

const useStyles = makeStyles(theme => ({
  buttonGroup: {
    margin: 0,
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  divider: {
    background: theme.palette.secondary.darker,
    margin: theme.spacing(5),
    height: '75%',
    [theme.breakpoints.down('xs')]: {
      display: 'none'
    }
  },
  campaigns: {
    display: 'flex',
    justifyContent: 'center',
    background: theme.palette.primary.light,
    height: theme.spacing(35),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      height: 'unset',
      display: 'flex',
      flexDirection: 'column'
    }
  },
  singleCampaign: {
    [theme.breakpoints.down('xs')]: {
      padding: 0,
      margin: `0 0 ${theme.spacing(2)}px 0`
    }
  },
  buyCampaign: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(6),
    paddingTop: theme.spacing(8),
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${confettiGif})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center'
  },
  sellCampaign: {
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(6),
    paddingTop: theme.spacing(8),
    justifyContent: 'center',
    alignItems: 'center',
    background: `url(${confettiGif})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      paddingBottom: theme.spacing(7),
      paddingTop: theme.spacing(3)
    }
  }
}));

const Campaign = ({ classes, type, text, disclaimer }) => {
  return (
    <div
      className={type === 'BUY' ? classes.buyCampaign : classes.sellCampaign}
    >
      <Typography
        color="primary"
        variant="h3"
        component="h3"
        style={{ margin: 0, fontWeight: 600 }}
      >
        {text}
      </Typography>
      <Typography variant="h6" style={{ margin: 0, fontWeight: 600 }}>
        {disclaimer}
      </Typography>
      <div className={classes.buttonGroup}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          to={type === 'BUY' ? '/productos' : '/vender'}
        >
          {type === 'BUY' ? 'QUIERO COMPRAR' : 'QUIERO VENDER'}
        </Button>
      </div>
    </div>
  );
};

const WelcomeRegular = ({ welcomeBuyerCampaign, welcomeSellerCampaign }) => {
  const classes = useStyles();
  const bothCampaigns = welcomeBuyerCampaign && welcomeSellerCampaign;
  if (!welcomeBuyerCampaign && !welcomeSellerCampaign) return <></>;
  return (
    <div className={bothCampaigns ? classes.campaigns : classes.singleCampaign}>
      {welcomeBuyerCampaign && (
        <Campaign
          classes={classes}
          type="BUY"
          text={`$${numberWithCommas(welcomeBuyerCampaign?.amount)} de REGALO`}
          disclaimer={`en tu primera compra + $${numberWithCommas(
            parseInt(welcomeBuyerCampaign?.minAmount) + 1
          )}`}
        />
      )}
      {welcomeBuyerCampaign && welcomeSellerCampaign && (
        <Divider orientation="vertical" className={classes.divider} />
      )}
      {welcomeSellerCampaign && (
        <Campaign
          classes={classes}
          type="SELL"
          text={`${numberWithCommas(
            welcomeSellerCampaign?.amount
          )}% de COMISIÓN`}
          disclaimer="en tu primera venta"
        />
      )}
    </div>
  );
};

export default WelcomeRegular;
