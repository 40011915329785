/**
 *
 * @param {Array} interestedIn the selected sub categories
 * @param {Array} selectedSizes the selected sizes
 * @param {Array} selectedBrands the selected brands
 * @param {Array} categories send the category tree to retrieve the sub categories matched with their parent category id
 * @param {string} payloadClass send the class payload when the payload is mixed, in case payload is empty whe need to check the related class of existing profiles
 * @param {boolean} isMixed when entities payload is mixed
 * @returns {Array}
 */

export const transformProfilesData = (
  interestedIn,
  selectedSizes,
  selectedBrands,
  categories,
  payloadClass = null,
  isMixed = true
) => {
  const preferenceTypeToCategory = {
    optionUserBottomKids: {
      name: 'Kids',
      sizeType: 'bottom'
    },
    optionUserBottomMujer: {
      name: 'Mujer',
      sizeType: 'bottom'
    },
    optionUserBottomHombre: {
      name: 'Hombre',
      sizeType: 'bottom'
    },
    optionUserTopKids: {
      name: 'Kids',
      sizeType: 'top'
    },
    optionUserTopMujer: {
      name: 'Mujer',
      sizeType: 'top'
    },
    optionUserTopHombre: {
      name: 'Hombre',
      sizeType: 'top'
    },
    optionUserShoesKids: {
      name: 'Kids',
      sizeType: 'shoes'
    },
    optionUserShoesMujer: {
      name: 'Mujer',
      sizeType: 'shoes'
    },
    optionUserShoesHombre: {
      name: 'Hombre',
      sizeType: 'shoes'
    }
  };

  const payload = {
    is_buyer: true,
    is_seller: true,
    is_payload_mixed: isMixed,
    entities: [],
    payload_class: payloadClass
  };

  if (selectedSizes) {
    for (const preferenceType in selectedSizes) {
      const relatedEntity = selectedSizes[preferenceType];
      if (relatedEntity !== null) {
        const categoryName = preferenceTypeToCategory[preferenceType].name;
        const sizeType = preferenceTypeToCategory[preferenceType].sizeType;
        if (categoryName) {
          const category = categories.find(cat => cat.name === categoryName);
          if (category) {
            payload.entities.push({
              related_class: 'Size',
              related_entity: relatedEntity,
              category_id: category.id,
              size_type: sizeType
            });
          }
        }
      }
    }
  }

  if (interestedIn) {
    for (const item of interestedIn) {
      payload.entities.push({
        related_class: 'SubCategory',
        related_entity: item.id,
        category_id: null,
        size_type: null
      });
    }
  }

  if (selectedBrands) {
    for (const item of selectedBrands) {
      payload.entities.push({
        related_class: 'Brand',
        related_entity: item,
        category_id: null,
        size_type: null
      });
    }
  }

  return payload;
};
