import {
  CREATE_ORDER_REQUESTED,
  CREATE_ORDER_SUCCESSFULL,
  CREATE_ORDER_ERRORED,
  DELETE_ORDER_REQUESTED,
  DELETE_ORDER_SUCCESSFULL,
  DELETE_ORDER_ERRORED,
  DELETE_ORDER_GROUP_REQUESTED,
  DELETE_ORDER_GROUP_SUCCESSFULL,
  DELETE_ORDER_GROUP_ERRORED,
  DELETE_SELLER_ORDER_REQUESTED,
  DELETE_SELLER_ORDER_SUCCESSFULL,
  DELETE_SELLER_ORDER_ERRORED,
  CONFIRM_ORDER_GROUP_REQUESTED,
  CONFIRM_ORDER_GROUP_SUCCESSFULL,
  CONFIRM_ORDER_GROUP_FAILED,
  SHIPPING_LABEL_REQUESTED,
  SHIPPING_LABEL_RETRIEVED,
  SHIPPING_LABEL_FAILED,
  SELLER_ORDERS_REQUESTED,
  SELLER_ORDERS_RETRIEVED,
  SELLER_ORDERS_FAILED,
  BUYER_ORDERS_REQUESTED,
  BUYER_ORDERS_RETRIEVED,
  BUYER_ORDERS_FAILED,
  ORDER_REQUESTED,
  ORDER_RETRIEVED,
  ORDER_FAILED,
  CREATE_SHIPPING_LABEL_REQUESTED,
  CREATE_SHIPPING_LABEL_SUCCESSFULL,
  CREATE_SHIPPING_LABEL_ERRORED,
  TRACKING_REQUESTED,
  TRACKING_RETRIEVED,
  TRACKING_ERRORED,
  ANSWER_REVIEW_SUCCESSFULL,
  CREATE_REVIEW_SUCCESSFULL,
  REFUND_REQUESTED,
  REFUND_SUCCESSFULL,
  REFUND_ERRORED,
  LOGOUT_REQUESTED,
  CHANGE_CANCELLATION_REQUESTED,
  CHANGE_CANCELLATION_SUCCESSFUL,
  CHANGE_CANCELLATION_FAILED,
  CREATE_CHANGE_SHIPPING_REQUESTED,
  CREATE_CHANGE_SHIPPING_SUCCESSFUL,
  CREATE_CHANGE_SHIPPING_FAILED
} from '../actions/types';

const defaultState = {
  currentOrder: null,
  justConfirmed: null,
  activeOrders: [],
  status: null,
  label: null,
  sellerOrdersStatus: null,
  buyerOrdersStatus: null,
  sellerOrders: [],
  buyerOrders: [],
  error: null,
  tracking: null,
  deletingOrder: null,
  confirmingOrder: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    case CREATE_ORDER_REQUESTED: {
      return {
        ...state,
        status: CREATE_ORDER_REQUESTED
      };
    }
    case CREATE_ORDER_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_ORDER_SUCCESSFULL,
        currentOrder: {
          ...action.order,
          timestamp: Math.floor(Date.now() / 1000)
        }
      };
    }
    case CREATE_ORDER_ERRORED: {
      return {
        ...state,
        status: CREATE_ORDER_ERRORED,
        error: {
          message:
            action.error.data?.error?.exception?.map(error => error.message) ||
            action.error.data?.message
        }
      };
    }
    case DELETE_ORDER_REQUESTED: {
      return {
        ...state,
        status: DELETE_ORDER_REQUESTED,
        deletingOrder: action.deletingOrder
      };
    }
    case DELETE_ORDER_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_ORDER_SUCCESSFULL,
        deletingOrder: action.deletingOrder,
        currentOrder: null
      };
    }
    case DELETE_ORDER_ERRORED: {
      return {
        ...state,
        status: DELETE_ORDER_ERRORED,
        deletingOrder: action.deletingOrder,
        error: action.error
      };
    }
    case DELETE_ORDER_GROUP_REQUESTED: {
      return {
        ...state,
        status: DELETE_ORDER_GROUP_REQUESTED,
        deletingOrder: action.deletingOrder
      };
    }
    case DELETE_ORDER_GROUP_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_ORDER_GROUP_SUCCESSFULL,
        deletingOrder: action.deletingOrder,
        currentOrder: null
      };
    }
    case DELETE_ORDER_GROUP_ERRORED: {
      return {
        ...state,
        status: DELETE_ORDER_GROUP_ERRORED,
        deletingOrder: action.deletingOrder,
        error: action.error
      };
    }
    case DELETE_SELLER_ORDER_REQUESTED: {
      return {
        ...state,
        status: DELETE_SELLER_ORDER_REQUESTED,
        deletingOrder: action.deletingOrder
      };
    }
    case DELETE_SELLER_ORDER_SUCCESSFULL: {
      return {
        ...state,
        status: DELETE_SELLER_ORDER_SUCCESSFULL,
        deletingOrder: action.deletingOrder,
        currentOrder: null
      };
    }
    case DELETE_SELLER_ORDER_ERRORED: {
      return {
        ...state,
        status: DELETE_SELLER_ORDER_ERRORED,
        deletingOrder: action.deletingOrder
      };
    }
    case CONFIRM_ORDER_GROUP_REQUESTED: {
      return {
        ...state,
        status: CONFIRM_ORDER_GROUP_REQUESTED,
        confirmingOrder: true
      };
    }
    case CONFIRM_ORDER_GROUP_SUCCESSFULL: {
      return {
        ...state,
        status: CONFIRM_ORDER_GROUP_SUCCESSFULL,
        currentOrder: null,
        confirmingOrder: false,
        justConfirmed: action.order,
        activeOrders: [...state.activeOrders, action.order]
      };
    }
    case CONFIRM_ORDER_GROUP_FAILED: {
      return {
        ...state,
        status: CONFIRM_ORDER_GROUP_FAILED,
        confirmingOrder: false
      };
    }
    case SHIPPING_LABEL_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_LABEL_REQUESTED,
        label: null
      };
    }
    case SHIPPING_LABEL_RETRIEVED: {
      return {
        ...state,
        status: SHIPPING_LABEL_RETRIEVED,
        label: action.label
      };
    }
    case SHIPPING_LABEL_FAILED: {
      return {
        ...state,
        status: SHIPPING_LABEL_FAILED,
        error: action.error
      };
    }
    case SELLER_ORDERS_REQUESTED: {
      return {
        ...state,
        status: SELLER_ORDERS_REQUESTED,
        sellerOrdersStatus: action.status
      };
    }
    case SELLER_ORDERS_RETRIEVED: {
      return {
        ...state,
        status: SELLER_ORDERS_RETRIEVED,
        sellerOrderStatus: action.status,
        sellerOrders: action.sellerOrders
      };
    }
    case SELLER_ORDERS_FAILED: {
      return {
        ...state,
        status: SELLER_ORDERS_FAILED,
        error: action.error,
        sellerOrdersStatus: action.status
      };
    }
    case BUYER_ORDERS_REQUESTED: {
      return {
        ...state,
        status: BUYER_ORDERS_REQUESTED,
        buyerOrdersStatus: action.status
      };
    }
    case BUYER_ORDERS_RETRIEVED: {
      return {
        ...state,
        status: BUYER_ORDERS_RETRIEVED,
        buyerOrdersStatus: action.status,
        buyerOrders: action.buyerOrders
      };
    }
    case BUYER_ORDERS_FAILED: {
      return {
        ...state,
        status: BUYER_ORDERS_FAILED,
        error: action.error,
        buyerOrdersStatus: action.status
      };
    }
    case ORDER_REQUESTED: {
      return {
        ...state,
        status: ORDER_REQUESTED
      };
    }
    case ORDER_RETRIEVED: {
      return {
        ...state,
        status: ORDER_RETRIEVED,
        currentOrder: action.order
      };
    }
    case ORDER_FAILED: {
      return {
        ...state,
        status: ORDER_FAILED,
        error: action.error
      };
    }
    case CREATE_SHIPPING_LABEL_REQUESTED: {
      return {
        ...state,
        status: CREATE_SHIPPING_LABEL_REQUESTED
      };
    }
    case CREATE_SHIPPING_LABEL_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_SHIPPING_LABEL_SUCCESSFULL,
        label: action.label
      };
    }
    case CREATE_SHIPPING_LABEL_ERRORED: {
      return {
        ...state,
        status: CREATE_SHIPPING_LABEL_ERRORED,
        error: action.error
      };
    }
    case TRACKING_REQUESTED: {
      return {
        ...state,
        status: TRACKING_REQUESTED
      };
    }
    case TRACKING_RETRIEVED: {
      return {
        ...state,
        status: TRACKING_RETRIEVED,
        tracking: action.tracking
      };
    }
    case TRACKING_ERRORED: {
      return {
        ...state,
        status: TRACKING_ERRORED,
        error: action.error
      };
    }
    case ANSWER_REVIEW_SUCCESSFULL: {
      return {
        ...state,
        status: ANSWER_REVIEW_SUCCESSFULL,
        currentOrder: {
          ...state.currentOrder,
          ...action.order
        }
      };
    }
    case CREATE_REVIEW_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_REVIEW_SUCCESSFULL,
        currentOrder: action.fromList
          ? state.currentOrder
          : {
              ...state.currentOrder,
              review: action.review
            }
      };
    }
    case REFUND_REQUESTED: {
      return {
        ...state,
        status: REFUND_REQUESTED
      };
    }
    case REFUND_SUCCESSFULL: {
      return {
        ...state,
        status: REFUND_SUCCESSFULL,
        currentOrder: action.order
      };
    }
    case REFUND_ERRORED: {
      let error = action.error?.data;
      if (
        action.error?.data?.error?.exception &&
        Array.isArray(action.error?.data?.error?.exception) &&
        action.error?.data?.error?.exception.length > 0
      ) {
        error = action.error.data.error.exception[0];
      } else if (action.error?.data?.error?.exception?.data) {
        error = action.error.data.error.exception.data;
      }
      return {
        ...state,
        status: REFUND_ERRORED,
        error
      };
    }
    case CREATE_CHANGE_SHIPPING_REQUESTED: {
      return {
        ...state,
        status: CREATE_CHANGE_SHIPPING_REQUESTED
      };
    }
    case CREATE_CHANGE_SHIPPING_SUCCESSFUL: {
      return {
        ...state,
        status: CREATE_CHANGE_SHIPPING_SUCCESSFUL
      };
    }
    case CREATE_CHANGE_SHIPPING_FAILED: {
      return {
        ...state,
        status: CREATE_CHANGE_SHIPPING_FAILED,
        error: action.error
      };
    }
    case CHANGE_CANCELLATION_REQUESTED: {
      return {
        ...state,
        status: CHANGE_CANCELLATION_REQUESTED
      };
    }
    case CHANGE_CANCELLATION_SUCCESSFUL: {
      return {
        ...state,
        status: CHANGE_CANCELLATION_SUCCESSFUL,
        currentOrder: action.order
      };
    }
    case CHANGE_CANCELLATION_FAILED: {
      return {
        ...state,
        status: REFUND_ERRORED,
        error: action.error
      };
    }
    default:
      return state;
  }
};
