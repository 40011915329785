import moment from 'moment';

// TODO(Tomas): Implemente DateService `groupNotifications` function.

export const getSecondsDiff = (date1, date2) => date2.diff(date1, 'seconds');

// The resulting variables work this way:
// If there are exactly two days between dates, it returns days = 2, all
// other variables in 0. If there is exceeding time besides from days, it
// will go on to the next conversion unit, and so on until minutes and seconds.
// `minGranularity` will be the the bigger unit to be used, by default it would be 'days' but could be: 'days', 'hours', 'minutes', or 'seconds'
export const getDifferenceDates = (
  oldestDate,
  newestDate = moment(),
  minGranularity = 'days'
) => {
  const oldDate = moment(oldestDate);
  const newDate = moment(newestDate);

  const duration = moment.duration(newDate.diff(oldDate));
  let days = parseInt(duration.asDays());

  let hours = parseInt(duration.asHours());

  hours = hours - days * 24;

  let minutes = parseInt(duration.asMinutes());

  minutes = minutes - (days * 24 * 60 + hours * 60);

  let seconds = parseInt(duration.asSeconds());

  seconds = seconds - (days * 24 * 60 + hours * 60 + minutes * 60);

  return {
    seconds,
    minutes,
    hours,
    days
  };
};

export const getDayDiffString = (oldestDate, newestDate = moment()) => {
  const { minutes, hours, days } = getDifferenceDates(oldestDate, newestDate);

  if (days > 0 && days > 30) {
    return 'mas de 30 dias';
  }
  if (days > 0) {
    return `${days} dia${days > 1 ? 's' : ''}`;
  }
  if (hours > 0) {
    return `${hours} hora${hours > 1 ? 's' : ''}`;
  }
  if (minutes > 0) {
    return `${minutes} minuto${minutes > 1 ? 's' : ''}`;
  }
  return 'menos de un minuto';
};

// This accumulates date difference in the hours and minutes.
// If there are two days of difference, it will result in 48 hours and so on.
export const getDateDifferenceInHours = baseDate => {
  const hours = moment
    .duration(moment(baseDate).add(48, 'hours') - moment())
    .asHours();
  const roundedHours = Math.floor(hours);
  const minutes = moment
    .duration(60 * (hours - roundedHours), 'minutes')
    .asMinutes();
  const roundedMinutes = Math.floor(minutes);

  return { hours: roundedHours, minutes: roundedMinutes };
};

export const today = () => moment();

export const apiStringTomoment = apiString => moment(apiString);

export const formatOrderCheckpoint = date => {
  const D = moment.isMoment(date) ? date : moment(date);

  return D.locale('es').format('D MMM [a las] H:mm[hs]');
};

export const diffDaysToToday = date => {
  if (!moment.isMoment(date)) {
    date = moment(date);
  }
  return moment().diff(date, 'days');
};
