import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Divider,
  Button,
  Typography,
  SvgIcon,
  Avatar,
  Hidden
} from '@material-ui/core';
import { Link, NavLink } from 'react-router-dom';
import { Android, Apple } from '@material-ui/icons';

import { ReactComponent as ShieldIcon } from '../images/Icons/shield.svg';
import { ReactComponent as SmileIcon } from '../images/Icons/smile.svg';
import { ReactComponent as InstagramIcon } from '../images/Icons/instagram.svg';
import { ReactComponent as FacebookIcon } from '../images/Icons/facebook.svg';
import { ReactComponent as TwitterIcon } from '../images/Icons/twitter.svg';

import RenovaLogo from '../images/ar-707-x-200.png';
import OcaLogo from '../images/oca-1.png';
import CorreoLogo from '../images/correo-arg-logo.png';
import MercadoLogo from '../images/isologo-mercado-pago.png';
import QrCode from '../images/dataweb.png';

const useStyles = makeStyles(theme => ({
  footer: ({ noMargin }) => ({
    padding: theme.spacing(2),
    display: 'flex',
    marginTop: noMargin ? 0 : theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    },
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    backgroundColor: theme.palette.primary.background,
    '& > hr': {
      alignSelf: 'stretch',
      height: 'auto'
    }
  }),
  socialFooter: {
    padding: theme.spacing(1),
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-evenly',
    '& > *': {
      margin: theme.spacing(1)
    },
    '& > hr': {
      [theme.breakpoints.up('md')]: {
        alignSelf: 'stretch',
        height: 'auto'
      }
    },
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    }
  },
  footerSection: {
    display: 'flex',
    flex: 1,
    flexFlow: 'column',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 4, 0, 4),
    alignItems: 'center',
    textAlign: 'center',
    '& > button': {
      width: '100%'
    },
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerTitle: {
    fontWeight: 700
  },
  socialAvatar: {
    color: theme.palette.secondary.light,
    backgroundColor: '#fff'
  },
  subtitle: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1)
    }
  },
  footerLink: {
    cursor: 'pointer',
    textDecoration: 'underline',
    color: theme.palette.secondary.main
  },
  faq: {
    margin: theme.spacing(0.5, 0, 0.5, 0)
  },
  footerButton: {
    width: '100%',
    '& > .MuiButton-label': {
      padding: 0
    }
  },
  legalFooter: {
    backgroundColor: '#000',
    color: '#fff',
    width: '100%',
    padding: '8px 125px 8px 125px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexFlow: 'column'
    }
  },
  legalLogos: {
    display: 'flex',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(0, 1, 0, 1)
    },
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'center',
      '& > *': {
        margin: theme.spacing(2)
      }
    }
  },
  faqLink: {
    color: '#414042',
    textDecoration: 'none',
    display: 'block'
  }
}));

const Footer = ({ onlyLegalFooter, ...props }) => {
  const classes = useStyles(props);
  return (
    <>
      {!onlyLegalFooter && (
        <>
          <div className={classes.footer}>
            <div className={classes.footerSection}>
              <Typography className={classes.footerTitle} color="primary">
                COMPRANDO
              </Typography>
              <div className={classes.faq}>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056405332--C%C3%B3mo-puedo-pagar-mi-compra-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo son las formas de pago?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056405472--Cu%C3%A1les-son-los-medios-de-env%C3%ADo-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo son los medios de envío?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056865351--C%C3%B3mo-funcionan-los-cambios-Informaci%C3%B3n-General"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo funcionan los cambios?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056406972--C%C3%B3mo-aplico-un-descuento-en-mi-compra-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo aplico un cupón de descuento?
                </Typography>
              </div>
              <Button
                className={classes.footerButton}
                component={Link}
                to="/como-funciona/como-comprar"
                variant="contained"
                color="primary"
              >
                CÓMO COMPRAR
              </Button>
              <div className={classes.subtitle}>
                <SvgIcon color="primary">
                  <ShieldIcon />
                </SvgIcon>
                <Typography style={{ fontStyle: 'italic' }} color="primary">
                  100% Segura
                </Typography>
              </div>
              <div className={classes.paragraphs}>
                <Typography>
                  Despacho en 5 días o te devolvemos tu dinero
                </Typography>
                <Typography>Todas tus compras tienen cambio</Typography>
              </div>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open(
                    'https://renovatuvestidor.zendesk.com/hc/es/articles/4731504442253'
                  )
                }
              >
                BOTÓN DE ARREPENTIMIENTO
              </Button>
            </div>
            <Hidden smDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider orientation="horizontal" />
            </Hidden>
            <div className={classes.footerSection}>
              <Typography className={classes.footerTitle} color="primary">
                VENDIENDO
              </Typography>
              <div className={classes.faq}>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056554411--C%C3%B3mo-vender-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo publico un producto?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056340732--Cu%C3%A1l-es-la-comisi%C3%B3n-que-me-cobra-Renov%C3%A1-Tu-Vestidor-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Qué costo tiene publicar?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056113792--C%C3%B3mo-realizo-el-env%C3%ADo-de-una-venta-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo envío mis productos?
                </Typography>
                <Typography
                  component="a"
                  href="https://renovatuvestidor.zendesk.com/hc/es/articles/360056800551--C%C3%B3mo-cobro-mis-ventas-"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.faqLink}
                >
                  · Cómo cobro mis ventas?
                </Typography>
              </div>
              <Button
                className={classes.footerButton}
                component={NavLink}
                to="/como-funciona/como-vender"
                variant="contained"
                color="primary"
              >
                CÓMO VENDER
              </Button>
              <div className={classes.subtitle}>
                <SvgIcon color="primary">
                  <SmileIcon />
                </SvgIcon>
                <Typography style={{ fontStyle: 'italic' }} color="primary">
                  Confiá
                </Typography>
              </div>
              <div className={classes.paragraphs}>
                <Typography>
                  Sin necesidad de encontrarte con tu compradora
                </Typography>
                <Typography>Cobrá tus ventas como más te convenga!</Typography>
              </div>
            </div>
            <Hidden smDown>
              <Divider orientation="vertical" />
            </Hidden>
            <Hidden smUp>
              <Divider orientation="horizontal" />
            </Hidden>
            <div className={classes.footerSection} style={{ margin: 0 }}>
              <div className={classes.socialFooter}>
                <div className={classes.socialFooterSection}>
                  <Typography className={classes.footerTitle}>
                    BAJATE LA APP
                  </Typography>
                  <div
                    className={classes.socialFooter}
                    style={{ flexFlow: 'row' }}
                  >
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href="https://play.google.com/store/apps/details?id=com.renovatuvestidor.rtvapp"
                      target="_blank"
                    >
                      <Android />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href="https://apps.apple.com/app/id1110486509"
                      target="_blank"
                    >
                      <Apple />
                    </Avatar>
                  </div>
                </div>
                <Hidden smDown>
                  <Divider orientation="vertical" />
                </Hidden>
                <Hidden smUp>
                  <Divider orientation="horizontal" />
                </Hidden>
                <div className={classes.socialFooterSection}>
                  <Typography className={classes.footerTitle}>
                    SEGUINOS!
                  </Typography>
                  <div
                    className={classes.socialFooter}
                    style={{ flexFlow: 'row' }}
                  >
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href="https://www.instagram.com/renovatuvestidor/"
                      target="_blank"
                    >
                      <InstagramIcon />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href="https://www.facebook.com/Renovatuvestidor/"
                      target="_blank"
                    >
                      <FacebookIcon />
                    </Avatar>
                    <Avatar
                      className={classes.socialAvatar}
                      component="a"
                      href="https://twitter.com/tuvestidor"
                      target="_blank"
                    >
                      <TwitterIcon />
                    </Avatar>
                  </div>
                </div>
              </div>
              <Typography>Te quedan más dudas?</Typography>
              <Button
                className={classes.footerButton}
                color="primary"
                variant="contained"
                onClick={() =>
                  window.open('https://renovatuvestidor.zendesk.com', '_blank')
                }
              >
                PREGUNTAS FRECUENTES
              </Button>
              <Button
                className={classes.footerButton}
                style={{
                  backgroundColor: '#fff',
                  color: '#403f41'
                }}
                variant="contained"
                onClick={() => {
                  if (typeof window.zE === 'function') {
                    window.zE('messenger', 'open');
                  }
                }}
              >
                CONTACTANOS
              </Button>
              <Typography
                className={classes.footerLink}
                component={Link}
                to="/ayuda/t&c"
                target="_blank"
              >
                Términos y condiciones
              </Typography>
              <Typography
                className={classes.footerLink}
                component={Link}
                to="/ayuda/politicas"
                target="_blank"
              >
                Políticas de privacidad
              </Typography>
            </div>
          </div>
        </>
      )}
      <div className={classes.legalFooter}>
        <div className={classes.renova}>
          <img src={RenovaLogo} alt="renova tu vestidor" />
        </div>
        <div className={classes.legalLogos}>
          <div
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.correoargentino.com.ar/', '_blank')
            }
          >
            <img src={CorreoLogo} alt="correo argentino" />
          </div>
          <div
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() => window.open('https://www.oca.com.ar/', '_blank')}
          >
            <img src={OcaLogo} alt="OCA" />
          </div>
          <div
            role="presentation"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              window.open('https://www.mercadopago.com.ar/home', '_blank')
            }
          >
            <img src={MercadoLogo} alt="Mercado Pago" />
          </div>
          <img
            style={{ height: '65px', cursor: 'pointer' }}
            src={QrCode}
            alt="AFIP QR CODE"
            onClick={() =>
              window.open(
                'http://qr.afip.gob.ar/?qr=RdtQSr-Fqq2k1RF16BeUrQ,,',
                '_blank'
              )
            }
          />
        </div>
      </div>
    </>
  );
};

export default Footer;
