import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDeviceToken, createDeviceToken } from '../../utils/deviceToken';
import { saveDevice } from '../../redux/actions/devices';

const withDeviceTokenGeneration = WrappedComponent => {
  return props => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const userId = useSelector(state => state.auth?.user?.id);
    const deviceInfo = useSelector(state => state.device.info);
    const processing = useSelector(state => state.device.processing);

    const saveDeviceToken = async () => {
      const userAgent = window.navigator.userAgent;
      const { user } = props;
      let os = 'unknow';
      let osVersion = 'unknow';

      const device = {
        user: user.id,
        adjustId: null,
        os,
        osVersion,
        deviceToken: getDeviceToken()
      };

      if (/Chrome/.test(userAgent)) {
        device.osVersion =
          (userAgent.match(/Chrome\/(\d+)/) || [])[1] || 'Unknown';
        device.os = 'Google Chrome';
      } else if (/Firefox/.test(userAgent)) {
        device.osVersion =
          (userAgent.match(/Firefox\/(\d+)/) || [])[1] || 'Unknown';
        device.os = 'Mozilla Firefox';
      } else if (/Safari/.test(userAgent)) {
        device.osVersion =
          (userAgent.match(/Version\/(\d+)/) || [])[1] || 'Unknown';
        device.os = 'Apple Safari';
      } else if (/MSIE|Trident/.test(userAgent)) {
        if (/MSIE/.test(userAgent)) {
          device.osVersion =
            (userAgent.match(/MSIE (\d+)/) || [])[1] || 'Unknown';
        } else {
          device.osVersion =
            (userAgent.match(/rv:(\d+)/) || [])[1] || 'Unknown';
        }
        device.os = 'Internet Explorer';
      } else if (/Edge/.test(userAgent)) {
        device.osVersion =
          (userAgent.match(/Edge\/(\d+)/) || [])[1] || 'Unknown';
        device.os = 'Microsoft Edge';
      } else if (/Opera|OPR/.test(userAgent)) {
        if (/OPR/.test(userAgent)) {
          device.osVersion =
            (userAgent.match(/OPR\/(\d+)/) || [])[1] || 'Unknown';
        } else {
          device.osVersion =
            (userAgent.match(/Opera\/(\d+)/) || [])[1] || 'Unknown';
        }
        device.os = 'Opera';
      }

      await dispatch(saveDevice(device));
    };

    useEffect(() => {
      if (isLoggedIn && !processing) {
        const deviceToken = getDeviceToken();

        if (!deviceToken) {
          createDeviceToken(userId);
          saveDeviceToken();
        } else if (!deviceInfo) {
          saveDeviceToken();
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn, props.location]);

    return <WrappedComponent {...props} />;
  };
};

export default withDeviceTokenGeneration;
