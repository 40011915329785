import store from '../../redux/store';
import AbstractRestClient from '../AbstractRestClient';
import axios from 'axios';

class AuthenticationRestClient extends AbstractRestClient {
  getUsername = () => store.getState().auth.user.username;

  login(username, password) {
    return this.instance.post('/api/es_ar/authentication/login', {
      username,
      password
    });
  }

  facebookLogin(token) {
    return this.instance.post('/api/es_ar/authentication/facebook', { token });
  }

  googleLogin(token) {
    return this.instance.post('/api/es_ar/authentication/google', { token });
  }

  register(user) {
    return this.instance.post('/api/es_ar/authentication/registers', user);
  }

  identifierExist(identifier) {
    return this.instance.post('/api/es_ar/authentication/identifiers/exists', {
      identifier
    });
  }

  resendPin(identifier = null, forceSms = false) {
    let force = forceSms ? 1 : 0;
    return this.instance.post('/api/es_ar/authentication/users/pins/resends', {
      identifier: identifier || this.getUsername(),
      forceSms: force
    });
  }

  sendPinViaCall(identifier) {
    const _identifier = identifier || this.getUsername();
    return this.instance.post('/api/es_ar/authentication/users/pins/calls', {
      identifier: _identifier
    });
  }

  cancelRegistration() {
    const username = this.getUsername();
    return this.instance.post(`/api/es_ar/authentication/cancels`, {
      identifier: username
    });
  }

  pinOptions(identifier) {
    return this.instance.post(`/api/es_ar/authentication/logins/pins/options`, {
      identifier
    });
  }

  requestLoginPin(identifier, channel) {
    return this.instance.post(
      `/api/es_ar/authentication/logins/pins/requests`,
      { identifier, channel }
    );
  }

  pinLogin(identifier, pin) {
    return this.instance.post(`/api/es_ar/authentication/logins/pins`, {
      identifier,
      pin
    });
  }

  heartbeat() {
    return this.instance.get('/api/heartbeat');
  }

  fetchGoogleInfo(access_token) {
    return axios.get('https://www.googleapis.com/oauth2/v3/userinfo', {
      headers: { Authorization: `Bearer ${access_token}` }
    });
  }
}

export default new AuthenticationRestClient();
