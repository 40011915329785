import lodash from 'lodash';

const TWO_FOR_ONE = 'TWO_FOR_ONE';
const THREE_FOR_TWO = 'THREE_FOR_TWO';
const FIFTEEN_PERCENT = 'FIFTEEN_PERCENT';
const TWENTY_PERCENT = 'TWENTY_PERCENT';
const TWENTYFIVE_PERCENT = 'TWENTYFIVE_PERCENT';
const THIRTY_PERCENT = 'THIRTY_PERCENT';
const FORTY_PERCENT = 'FORTY_PERCENT';
const FREE_SHIPPING = 'FREE_SHIPPING';
const DISCOUNT_PRODUCTS = 'DISCOUNT_PRODUCTS';
const FIXED_SHIPPING = 'FIXED_SHIPPING';
const DISCOUNT_SHIPPING = 'DISCOUNT_SHIPPING';

export const promotionTranslate = promotion => {
  let promotionType = promotion.type;
  switch (promotionType) {
    case TWO_FOR_ONE:
      return '2x1';
    case THREE_FOR_TWO:
      return '3x2';
    case FIFTEEN_PERCENT:
      return '15% Off';
    case TWENTY_PERCENT:
      return '20% Off';
    case TWENTYFIVE_PERCENT:
      return '25% Off';
    case THIRTY_PERCENT:
      return '30% Off';
    case FORTY_PERCENT:
      return '40% Off';
    case FREE_SHIPPING:
      return 'Envío gratis';
    case DISCOUNT_PRODUCTS:
      return 'Descuento en productos';
    case FIXED_SHIPPING:
      return 'Envío fijo';
    case DISCOUNT_SHIPPING:
      return 'Descuento en envío';
    default:
      return 'Promocion Desconocida';
  }
};

export const discountTranslate = discount => {
  let discountEntity = discount.entity;
  switch (discountEntity) {
    case 'GiftCard':
      return `Giftcard: ${discount.code}`;
    case 'Coupon':
      return `Cupón: ${discount.code}`;
    default:
      return promotionTranslate(discount);
  }
};

const applyPromotion = (product, discount) => {
  // if promo is live, apply discount
  if (promoIsLive(product)) {
    const discountedPrice = (product.final_price * discount).toFixed(1);
    product.price_promo_applied = parseFloat(discountedPrice);
    return product;
  }
  // if promo is expired, return product
  return product;
};

export const productsAfterPromo = array => {
  let products = lodash.cloneDeep(array);
  // we add a new field to the product object to store the price after the promotion is applied
  products = products.map(product => {
    product.price_promo_applied = product.final_price;
    return product;
  });

  const promotionType = products[0]?.user?.promotion?.type || null;

  if (promotionType) {
    switch (promotionType) {
      case TWENTY_PERCENT:
        return products.map(product => applyPromotion(product, 0.8));
      case THIRTY_PERCENT:
        return products.map(product => applyPromotion(product, 0.7));
      case FORTY_PERCENT:
        return products.map(product => applyPromotion(product, 0.6));
      case TWO_FOR_ONE:
        // If we have at least 2 products, the cheapest is free
        if (products.length > 1) {
          products.sort((a, b) => a.final_price - b.final_price);
          const promoAppliedProducts = Math.floor(products.length / 2);
          const result = products.map((product, index) => {
            if (promoIsLive(product)) {
              return {
                ...product,
                price_promo_applied:
                  index < promoAppliedProducts ? 0 : product.final_price
              };
            }
            return {
              ...product,
              price_promo_applied: product.final_price
            };
          });

          return result;
        }
        break;
      case THREE_FOR_TWO:
        // If we have at least 3 products, the cheapest is free
        if (products.length > 2) {
          products.sort((a, b) => a.final_price - b.final_price);
          const promoAppliedProducts = Math.floor(products.length / 3);
          const result = products.map((product, index) => {
            if (promoIsLive(product)) {
              return {
                ...product,
                price_promo_applied:
                  index < promoAppliedProducts ? 0 : product.final_price
              };
            }
            return {
              ...product,
              price_promo_applied: product.final_price
            };
          });

          return result;
        }
        break;
      default:
        return products;
    }
  }
  return products;
};

export const getProductsPromotions = products => {
  if (!products) return [];
  const productsPromotionAvailable = products
    .filter(p => p.user.promotion && promoIsLive(p))
    .reduce((products, product) => {
      if (products.find(p => p.user.id === product.user.id)) {
        return products;
      }
      return [...products, product];
    }, [])
    .map(p => p.user.promotion);
  if (!productsPromotionAvailable.length) return [];
  return productsPromotionAvailable;
};

export const promoIsLive = product => {
  if (!product) return false;
  if (!product.user.promotion) return false;
  if (new Date(product.user.promotion.date_end) < new Date()) return false;
  return true;
};
