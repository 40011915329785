import moment from 'moment';
import { v5 as uuidv5 } from 'uuid';

const RTV_DEVICE_TOKEN = 'RTV_DEVICE_TOKEN';

export const getDeviceToken = () => {
  const deviceString = localStorage.getItem(RTV_DEVICE_TOKEN);
  if (!deviceString) return null;

  try {
    const device = JSON.parse(deviceString);
    return device.token;
  } catch (error) {
    return null;
  }
};

export const createDeviceToken = (userId = 0) => {
  const date = moment().format('YYYY-MM-DDTHH:mm:ss.SSS');
  const brwoserInfo = [
    window.navigator.userAgent,
    window.navigator.language,
    window.screen.width,
    window.screen.height,
    window.screen.colorDepth,
    new Date().getTimezoneOffset(),
    window.navigator.hardwareConcurrency || 'unknown',
    new Intl.Collator().resolvedOptions().locale,
    `${date}-${Math.random()}`,
    userId
  ];

  const fingerprint = brwoserInfo.join('/');
  const deviceToken = uuidv5(fingerprint, process.env.REACT_APP_UUID_NAMESPACE);
  const device = {
    date,
    token: deviceToken
  };
  localStorage.setItem(RTV_DEVICE_TOKEN, JSON.stringify(device));
};
