import React from 'react';
import MaskedInput from 'react-text-mask';

const DateInput = ({ inputRef, ...other }) => {
  const dateMask = rawValue => {
    const dayFirstDigit = rawValue.length > 0 ? rawValue[0] : /[0-3]/;
    let daySecondDigit;

    if (dayFirstDigit === '3') {
      daySecondDigit = /[0-1]/;
    } else if (dayFirstDigit === '0') {
      daySecondDigit = /[1-9]/;
    } else if (dayFirstDigit >= '1' && dayFirstDigit <= '2') {
      daySecondDigit = /[0-9]/;
    } else {
      daySecondDigit = /[0-9]/;
    }

    const monthFirstDigit = rawValue.length > 3 ? rawValue[3] : /[0-1]/;
    let monthSecondDigit;

    if (monthFirstDigit === '1') {
      monthSecondDigit = /[0-2]/;
    } else if (monthFirstDigit === '0') {
      monthSecondDigit = /[1-9]/;
    } else {
      monthSecondDigit = /[0-9]/;
    }

    const yearFirstDigit = rawValue.length > 6 ? rawValue[6] : /[1-2]/;
    let yearSecondDigit;

    if (yearFirstDigit === '1') {
      yearSecondDigit = /[9]/;
    } else if (yearFirstDigit === '2') {
      yearSecondDigit = /[0]/;
    } else {
      yearSecondDigit = /[0-9]/;
    }

    return [
      /[0-3]/,
      daySecondDigit,
      '/',
      /[0-1]/,
      monthSecondDigit,
      '/',
      /[1-2]/,
      yearSecondDigit,
      /[0-9]/,
      /[0-9]/
    ];
  };

  return (
    <MaskedInput
      {...other}
      ref={ref => {
        inputRef(ref ? ref.inputElement : null);
      }}
      guide={false}
      mask={dateMask}
    />
  );
};

export default DateInput;
