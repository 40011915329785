import Reactotron from '../reactotronConfig';
import store from '../redux/store';

const log = (event, payload) => {
  Reactotron.display({
    name: `Analytics Event ${event}`,
    payload
  });
};

const productsMap = products => {
  return products.map(product => ({
    id: product.id,
    price: product.selling_price,
    title: product.title,
    brand: product.brand?.name,
    brand_id: product.brand?.id,
    category: product.sub_sub_category?.name,
    category_id: product.sub_sub_category?.id
  }));
};

const translatePaymentMethod = paymentMethod => {
  const PAYMENT_TYPES = {
    CARD: 'tarjeta',
    BANK: 'transferencia',
    OFFLINE: 'efectivo',
    CREDIT: 'credito'
  };

  if (!paymentMethod.paymentInType && !paymentMethod.mercadoPagoMethod) {
    return PAYMENT_TYPES.CREDIT;
  }

  if (paymentMethod.mercadoPagoMethod && paymentMethod.mercadoPagoMethod.card) {
    return PAYMENT_TYPES.CARD;
  } else if (paymentMethod.mercadoPagoMethod) {
    return PAYMENT_TYPES.OFFLINE;
  }

  if (
    paymentMethod.paymentInType &&
    paymentMethod.paymentInType === 'Rapipago'
  ) {
    return PAYMENT_TYPES.OFFLINE;
  }

  return PAYMENT_TYPES.BANK;
};

class AnalyticsService {
  constructor() {
    this.analytics = window.firebase && window.firebase.analytics;
  }

  getCurrency() {
    // As of right now, we only care for Argentinian currency but we might have to have
    // a variable or checking out the user's locale in order to decide which currency
    // we should be using.
    return 'ARS';
  }

  getUser() {
    const userReducer = store.getState().user;

    if (!userReducer || !userReducer.user) {
      return null;
    }

    return {
      id: userReducer.user.id,
      email: userReducer.privateStats.email
    };
  }

  pushToDataLayer(payload) {
    const dataLayer = window.dataLayer || [];
    const isLoggedIn =
      store.getState().auth && store.getState().auth.isLoggedIn;
    payload = {
      ...payload,
      user: isLoggedIn ? this.getUser() : null,
      _clear: true // to prevent default recursive merge: https://github.com/google/data-layer-helper?tab=readme-ov-file#preventing-default-recursive-merge
    };
    dataLayer.push(payload);
  }

  pageView(path) {
    if (path === '/') {
      // When we visit / page we are redirected to /home by the app, so we
      // shouldn't log this pageView.
      return;
    }
    this.analytics().setCurrentScreen(path);
    if (path === '/checkout/carrito') {
      // This particular case will be called in the cart component
      // which will call other event (visitCart).
      return;
    }
    if (path === '/home') {
      this.homeVisit();
      return;
    }
    if (path.substring(0, 10) === '/productos') {
      return;
    } else if (path.substring(0, 9) === '/producto') {
      // We want to avoid sending the pageView event when we enter
      // on a product detail page as well since we have other event for
      // that case.
      return;
    }
    this.analytics().logEvent('navegacion', { path });
    this.pushToDataLayer({
      event: 'pageView'
    });
    log('Navegación', { path });
  }

  homeVisit() {
    this.analytics().logEvent('Ver la home');
    this.pushToDataLayer({
      event: 'homeVisit'
    });
    log('Ver la home', {});
  }

  productsListVisit(products) {
    this.analytics().logEvent('Ver pagina de productos', { products });
    this.pushToDataLayer({
      event: 'productsListVisit',
      items: productsMap(products)
    });
    log('Ver pagina de productos', { products });
  }

  visitCart(cart) {
    this.analytics().logEvent('Ver el carrito', { cart });
    this.pushToDataLayer({
      event: 'visitCart',
      items: productsMap(cart),
      currency: this.getCurrency(),
      quantity: cart.length,
      value: cart.reduce((count, product) => count + product.selling_price, 0)
    });
    log('Ver el carrito', { cart });
  }

  visitProduct(product) {
    this.analytics().logEvent('Ver contenido', { product });
    this.pushToDataLayer({
      event: 'visitProduct',
      items: productsMap([product])
    });
    log('Ver contenido', { product });
  }

  completeRegister() {
    this.analytics().logEvent('Completar registro');
    this.pushToDataLayer({
      event: 'completeRegister'
    });
    log('Completar registro');
  }

  offerSent(product, offer) {
    this.analytics().logEvent('Ofertar', { product, offer });
    this.pushToDataLayer({
      event: 'offerSent',
      items: productsMap([product])
    });
    log('Ofertar', { product, offer });
  }

  paymentStart(products, buyer) {
    this.analytics().logEvent('Iniciar pago', { products, buyer });
    this.pushToDataLayer({
      event: 'paymentStart',
      items: productsMap(products),
      currency: this.getCurrency(),
      quantity: products.length,
      value: products.reduce(
        (count, product) => count + product.selling_price,
        0
      )
    });
    log('Iniciar pago', { products, buyer });
  }

  addPaymentInfo(products, shippingCost, discount) {
    this.analytics().logEvent('Agregar información de pago', {
      products,
      shippingCost,
      discount
    });
    this.pushToDataLayer({
      event: 'addPaymentInfo',
      items: productsMap(products),
      currency: this.getCurrency(),
      quantity: products.length,
      value:
        products.reduce((count, product) => count + product.selling_price, 0) +
        (shippingCost ? shippingCost : 0) -
        (discount ? discount : 0)
    });
    log('Agregar información de pago', { products, shippingCost, discount });
  }

  purchase(products, total, paymentMethod, order) {
    this.analytics().logEvent('Comprar', {
      products,
      total,
      paymentMethod,
      order
    });
    this.pushToDataLayer({
      event: 'purchase',
      items: productsMap(products),
      currency: this.getCurrency(),
      quantity: products.length,
      value: total,
      payment_type: translatePaymentMethod(paymentMethod),
      transaction: order.id
    });
    log('Comprar', { products, total, paymentMethod, order });
  }

  newListing(product) {
    this.analytics().logEvent('Listing nuevo', { product });
    this.pushToDataLayer({
      event: 'newListing'
    });
    log('Listing nuevo', { product });
  }

  favourite(product) {
    this.analytics().logEvent('Agregado a favoritos', { product });
    this.pushToDataLayer({
      event: 'favourite',
      items: productsMap([product])
    });
    log('Agregado a favoritos', { product });
  }

  wishlist(product) {
    this.analytics().logEvent('Avisarme si se libera', { product });
    this.pushToDataLayer({
      event: 'wishlist',
      items: productsMap([product])
    });
    log('Avisarme si se libera', { product });
  }
}

export default new AnalyticsService();
