import {
  STATES_REQUESTED,
  STATES_RETRIEVED,
  STATES_FAILED,
  REGIONS_REQUESTED,
  REGIONS_RETRIEVED,
  REGIONS_FAILED,
  CARRIERS_REQUESTED,
  CARRIERS_RETRIEVED,
  CARRIERS_FAILED,
  STORES_REQUESTED,
  STORES_RETRIEVED,
  STORES_FAILED,
  SHIPPING_ESTIMATE_REQUESTED,
  SHIPPING_ESTIMATE_RETRIEVED,
  SHIPPING_ESTIMATE_FAILED,
  SHIPPING_MULTI_ESTIMATE_REQUESTED,
  SHIPPING_MULTI_ESTIMATE_RETRIEVED,
  SHIPPING_MULTI_ESTIMATE_FAILED,
  PICKUP_STORES_REQUESTED,
  PICKUP_STORES_RETRIEVED,
  PICKUP_STORES_FAILED
} from '../actions/types';

const defaultState = {
  states: [],
  carriers: [],
  loading: false,
  stores: {},
  status: null,
  error: null,
  estimate: null,
  pickupStores: [],
  carrierStates: {},
  regions: []
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case STATES_REQUESTED: {
      return {
        ...state,
        error: null,
        status: STATES_REQUESTED
      };
    }
    case STATES_RETRIEVED: {
      return {
        ...state,
        error: null,
        states: action.carrier ? state.states : action.states,
        status: STATES_RETRIEVED,
        carrierStates: !action.carrier
          ? state.carrierStates
          : {
              ...state.carrierStates,
              [action.carrier]: {
                ...state.carrierStates[action.carrier],
                [action.action]: action.states
              }
            }
      };
    }
    case STATES_FAILED: {
      return {
        ...state,
        error: action.error,
        status: STATES_FAILED
      };
    }
    case REGIONS_REQUESTED: {
      return {
        ...state,
        status: REGIONS_REQUESTED,
        regions: [],
        regionsState: action.state
      };
    }
    case REGIONS_RETRIEVED: {
      return {
        ...state,
        status: REGIONS_RETRIEVED,
        regions: action.regions,
        regionsState: action.state
      };
    }
    case REGIONS_FAILED: {
      return {
        ...state,
        status: REGIONS_FAILED,
        error: action.error,
        regionsState: action.state
      };
    }
    case CARRIERS_REQUESTED: {
      return {
        ...state,
        error: action.error,
        carriers: [],
        loading: true,
        status: CARRIERS_REQUESTED
      };
    }
    case CARRIERS_RETRIEVED: {
      return {
        ...state,
        error: action.error,
        loading: false,
        carriers: action.carriers,
        status: CARRIERS_RETRIEVED
      };
    }
    case CARRIERS_FAILED: {
      return {
        ...state,
        error: action.error,
        carriers: [],
        loading: false,
        status: CARRIERS_FAILED
      };
    }
    case STORES_REQUESTED: {
      return {
        ...state,
        status: STORES_REQUESTED,
        stores: {
          ...state.stores,
          requesting: action.carrier,
          [action.carrier]: []
        }
      };
    }
    case STORES_RETRIEVED: {
      return {
        ...state,
        status: STORES_RETRIEVED,
        stores: {
          ...state.stores,
          requesting: action.carrier,
          [action.carrier]: action.stores
        }
      };
    }
    case STORES_FAILED: {
      return {
        ...state,
        status: STORES_FAILED,
        stores: {
          ...state.stores,
          requesting: action.carrier,
          [action.carrier]: [],
          error: action.error
        }
      };
    }
    case SHIPPING_ESTIMATE_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_ESTIMATE_REQUESTED,
        estimate: null
      };
    }
    case SHIPPING_ESTIMATE_RETRIEVED: {
      return {
        ...state,
        status: SHIPPING_ESTIMATE_RETRIEVED,
        estimate: action.estimate
      };
    }
    case SHIPPING_ESTIMATE_FAILED: {
      return {
        ...state,
        status: SHIPPING_ESTIMATE_FAILED
      };
    }
    case SHIPPING_MULTI_ESTIMATE_REQUESTED: {
      return {
        ...state,
        status: SHIPPING_MULTI_ESTIMATE_REQUESTED,
        estimate: null
      };
    }
    case SHIPPING_MULTI_ESTIMATE_RETRIEVED: {
      return {
        ...state,
        status: SHIPPING_MULTI_ESTIMATE_RETRIEVED,
        estimate: action.estimate
      };
    }
    case SHIPPING_MULTI_ESTIMATE_FAILED: {
      return {
        ...state,
        status: SHIPPING_MULTI_ESTIMATE_FAILED
      };
    }
    case PICKUP_STORES_REQUESTED: {
      return {
        ...state,
        status: PICKUP_STORES_REQUESTED
      };
    }
    case PICKUP_STORES_RETRIEVED: {
      return {
        ...state,
        status: PICKUP_STORES_RETRIEVED,
        pickupStores: action.pickupStores
      };
    }
    case PICKUP_STORES_FAILED: {
      return {
        ...state,
        status: PICKUP_STORES_FAILED
      };
    }
    default:
      return state;
  }
};
