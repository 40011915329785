import {
  SIZES_REQUESTED,
  SIZES_RETRIEVED,
  SIZES_FAILED,
  SIZES_ALL_REQUESTED,
  SIZES_ALL_RETRIEVED,
  SIZES_ALL_FAILED,
  CATEGORIES_REQUESTED,
  CATEGORIES_RETRIEVED,
  CATEGORIES_FAILED,
  BRANDS_REQUESTED,
  BRANDS_RETRIEVED,
  BRANDS_FAILED,
  FEATURED_BRANDS_REQUESTED,
  FEATURED_BRANDS_RETRIEVED,
  FEATURED_BRANDS_FAILED,
  SUBSUBCAT_REQUESTED,
  SUBSUBCAT_RETRIEVED,
  SUBSUBCAT_FAILED,
  COLORS_REQUESTED,
  COLORS_SUCCESSFULL,
  COLORS_ERRORED,
  TAGS_REQUESTED,
  TAGS_SUCCESSFULL,
  TAGS_ERRORED
} from '../actions/types';
import hashTree from '../../utils/hashTree';
let defaultTree = [];
if (process.env.REACT_APP_ENV === 'production') {
  defaultTree = require('./tree.production.json');
} else {
  defaultTree = require('./tree.staging.json');
}

const defaultState = {
  sizes: [],
  allSizes: [],
  brands: [],
  colors: [],
  tags: [],
  featuredBrands: [],
  categories: defaultTree,
  hashedCategories: hashTree(defaultTree),
  category: null,
  status: null,
  error: null
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case SIZES_REQUESTED: {
      return {
        ...state,
        status: SIZES_REQUESTED
      };
    }
    case SIZES_RETRIEVED: {
      return {
        ...state,
        status: SIZES_RETRIEVED,
        sizes: action.sizes
      };
    }
    case SIZES_FAILED: {
      return {
        ...state,
        status: SIZES_FAILED,
        error: action.error
      };
    }
    case SIZES_ALL_REQUESTED: {
      return {
        ...state,
        status: SIZES_ALL_REQUESTED
      };
    }
    case SIZES_ALL_RETRIEVED: {
      return {
        ...state,
        status: SIZES_ALL_RETRIEVED,
        allSizes: action.sizes
      };
    }
    case SIZES_ALL_FAILED: {
      return {
        ...state,
        status: SIZES_ALL_FAILED,
        error: action.error
      };
    }
    case BRANDS_REQUESTED: {
      return {
        ...state,
        status: BRANDS_REQUESTED
      };
    }
    case BRANDS_RETRIEVED: {
      return {
        ...state,
        status: BRANDS_RETRIEVED,
        brands: action.brands
      };
    }
    case BRANDS_FAILED: {
      return {
        ...state,
        status: BRANDS_FAILED,
        error: action.error
      };
    }
    case FEATURED_BRANDS_REQUESTED: {
      return {
        ...state,
        status: FEATURED_BRANDS_REQUESTED
      };
    }
    case FEATURED_BRANDS_RETRIEVED: {
      return {
        ...state,
        status: FEATURED_BRANDS_RETRIEVED,
        featuredBrands: action.brands
      };
    }
    case FEATURED_BRANDS_FAILED: {
      return {
        ...state,
        status: FEATURED_BRANDS_FAILED,
        error: action.error
      };
    }
    case CATEGORIES_REQUESTED: {
      return {
        ...state,
        status: CATEGORIES_REQUESTED
      };
    }
    case CATEGORIES_RETRIEVED: {
      return {
        ...state,
        status: CATEGORIES_RETRIEVED,
        categories: action.categories,
        hashedCategories: hashTree(action.categories)
      };
    }
    case CATEGORIES_FAILED: {
      return {
        ...state,
        status: CATEGORIES_FAILED,
        error: action.error
      };
    }
    case SUBSUBCAT_REQUESTED: {
      return {
        ...state,
        status: SUBSUBCAT_REQUESTED
      };
    }
    case SUBSUBCAT_RETRIEVED: {
      return {
        ...state,
        status: SUBSUBCAT_RETRIEVED,
        category: action.category
      };
    }
    case SUBSUBCAT_FAILED: {
      return {
        ...state,
        status: SUBSUBCAT_FAILED,
        error: action.error
      };
    }
    case COLORS_REQUESTED: {
      return {
        ...state,
        status: COLORS_REQUESTED
      };
    }
    case COLORS_SUCCESSFULL: {
      return {
        ...state,
        status: COLORS_SUCCESSFULL,
        colors: action.colors
      };
    }
    case COLORS_ERRORED: {
      return {
        ...state,
        status: COLORS_ERRORED
      };
    }
    case TAGS_REQUESTED: {
      return {
        ...state,
        status: TAGS_REQUESTED
      };
    }
    case TAGS_SUCCESSFULL: {
      return {
        ...state,
        status: TAGS_SUCCESSFULL,
        tags: action.tags
      };
    }
    case TAGS_ERRORED: {
      return {
        ...state,
        status: TAGS_ERRORED
      };
    }
    default:
      return state;
  }
};
