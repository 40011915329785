import React, { Suspense } from 'react';
import { CircularProgress, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import store, { persistor, history } from './redux/store';
import theme from './theme';
import AppRouting from './AppRouting';
import RTVLoadingHanger from './commons/loading/RTVLoadingHanger';
import './App.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ErrorsAlertProvider } from './providers/ErrorsAlertProvider';

const Loader = () => (
  <div
    style={{
      width: '100vw',
      height: '100vh',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}
  >
    <CircularProgress />
  </div>
);

function App() {
  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Provider store={store}>
          <ErrorsAlertProvider>
            <PersistGate persistor={persistor} loading={<Loader />}>
              <ConnectedRouter history={history}>
                <Suspense fallback={<RTVLoadingHanger fullScreen />}>
                  <AppRouting />
                </Suspense>
              </ConnectedRouter>
            </PersistGate>
          </ErrorsAlertProvider>
        </Provider>
      </ThemeProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
