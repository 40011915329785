import React, { useEffect, useState } from 'react';
import querystring from 'querystring';
import { useLocation, useHistory } from 'react-router';
import RTVLoadingHanger from '../../commons/loading/RTVLoadingHanger';
import axiosInstance from '../../http/axiosConfig';
import { Button } from '@material-ui/core';
import RTVDialog from '../../commons/dialogs/RTVDialog';
import { deleteOrderGroup } from '../../redux/actions/order';
import { useDispatch } from 'react-redux';

const sleep = milliseconds => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

const MercadoPagoCredit = () => {
  const location = useLocation();
  const history = useHistory();
  const [dialog, setDialog] = useState({
    open: false,
    title: '',
    message: '',
    onClose: ''
  });
  const dispatch = useDispatch();
  const params = querystring.decode(location.search.replace('?', ''));
  const { payment_id: paymentID, order: orderID, paymentStatus } = params;

  function checkOrder(count) {
    let checkCount = count;
    axiosInstance
      .get(`/api/es_ar/orders/${orderID}/group`)
      .then(async response => {
        if (response.data.status === 'STATUS_ACCREDITED') {
          const order = response.data;
          let products = [];
          order.purchase_orders.forEach(po => {
            products = [...products, ...po.products];
          });
          history.replace({
            pathname: '/checkout/proceso-terminado',
            state: {
              // replace content with the order retrieved
              paymentMethod: order.financial_record_in,
              products,
              total: order.total_products_buying_price,
              order: order
            }
          });
        } else if (
          response.data.status === 'STATUS_WAITING_ON_FINANCIAL_RECORD_IN' &&
          checkCount < 6
        ) {
          await sleep(2000);
          checkCount = checkCount + 1;
          checkOrder(checkCount);
        } else if (response.data.status === 'STATUS_CANCELLED_BY_PAYMENT') {
          // MELI rejected the payment, we should show an error,
          // we should return the user to the the cart again.
          setDialog({
            open: true,
            title: 'Ooops',
            message:
              'El pago ha sido rechazado por Mercadopago. Podés volver a intentar nuevamente el proceso de compra',
            onClose: () => {
              setDialog(dialog => ({ ...dialog, open: false }));
              history.replace('/checkout/carrito');
            }
          });
        } else {
          setDialog({
            open: true,
            title: 'Hey!',
            message:
              'Parece que tu pago sigue estando en revisión, revisá más tarde en Mis Compras',
            onClose: () => {
              setDialog(dialog => ({ ...dialog, open: false }));
              history.replace('/mis-compras');
            }
          });
        }
      })
      .catch(() => {
        // An error ocurred while requesting the status of the order
        // inform the user about the error and send the MyPurchases page.
        setDialog({
          open: true,
          title: 'Ooops',
          message:
            'Hubo un problema al consultar el estado de tu orden, revisá más tarde en Mis Compras el estado de tu pago.',
          onClose: () => {
            setDialog(dialog => ({ ...dialog, open: false }));
            history.replace('/mis-compras');
          }
        });
      });
  }

  useEffect(() => {
    if (
      (paymentStatus && paymentStatus === 'failure') ||
      !paymentID ||
      paymentID === 'null'
    ) {
      dispatch(deleteOrderGroup({ id: orderID }));
      history.replace('/checkout/carrito');
      return;
    }
    checkOrder(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <RTVDialog
        open={dialog.open}
        title={dialog.title}
        message={dialog.message}
        onClose={dialog.onClose}
        actionSection={
          <Button variant="contained" color="primary" onClick={dialog.onClose}>
            Ok
          </Button>
        }
      />
      <RTVLoadingHanger fullScreen message="Estamos verificando tu pago..." />
    </>
  );
};

export default MercadoPagoCredit;
