import AbstractRestClient from '../AbstractRestClient';

class CategoriesRestClient extends AbstractRestClient {
  fetchSizes(isOldVersion = true) {
    const endpoint = isOldVersion ? '/api/es_ar/sizes' : '/api/es_ar/sizes/v2';
    return this.instance.get(endpoint);
  }

  fetchAllSizes() {
    return this.instance.get('/api/es_ar/sizes/all');
  }

  fetchBrands(filter = '') {
    return this.instance.get(`/api/es_ar/brands?filter=${filter}`);
  }

  fetchCategories() {
    return this.instance.get('/api/es_ar/categories/tree');
  }

  fetchSubSubCategory(category) {
    return this.instance.get(`/api/es_ar/subsubcategories/${category}`);
  }

  fetchColors() {
    return this.instance.get('/api/es_ar/colors');
  }

  fetchTags() {
    return this.instance.get('/api/es_ar/tags');
  }
}

export default new CategoriesRestClient();
