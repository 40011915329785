import { applyMiddleware, compose as reduxCompose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { wrapHistory } from 'oaf-react-router';
import { routerMiddleware } from 'connected-react-router';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import promiseMiddleware from 'redux-promise';
import createRootReducer from './reducers';
import Reactotron from '../reactotronConfig';

export const history = createBrowserHistory();
wrapHistory(history);

const compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || reduxCompose;

// This configuration allows to initialize a store with predefinied values for testings,
// passing an initialState
export const configureStore = initialState => {
  const persistConfig = {
    key: 'root',
    storage,
    stateReconciler: autoMergeLevel2,
    blacklist: [
      'product',
      'publicity',
      'comments',
      'favourite',
      'shipping',
      'categories'
    ]
  };

  const combinedReducer = createRootReducer(history);
  const pReducer = persistReducer(persistConfig, combinedReducer);
  const enhancer = compose(
    applyMiddleware(routerMiddleware(history), thunk, promiseMiddleware),
    Reactotron.createEnhancer()
  );

  return createStore(pReducer, initialState, enhancer);
};

const store = configureStore();

export const persistor = persistStore(store);

export default store;
