import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import {
  List as VList,
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  InfiniteLoader
} from 'react-virtualized';
import { fetchFeed } from '../../redux/actions/social';
import LoadingGif from '../../images/Loading.gif';
import {
  SOCIAL_FEED_RETRIEVED,
  SOCIAL_FEED_REQUESTED,
  SOCIAL_FEED_FAILED
} from '../../redux/actions/types';
import NotificationFactory from '../../commons/notifications/NotificationFactory';
import 'react-virtualized/styles.css';
import { makeStyles } from '@material-ui/core';

const cache = new CellMeasurerCache({
  fixedWidth: true,
  defaultHeight: 100,
  minHeight: 90
});

const Feed = () => {
  const [feed, setFeed] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();

  const status = useSelector(state => state.social.status);
  const socialFeed = useSelector(state => state.social.feed);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchFeed());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (status === SOCIAL_FEED_REQUESTED) {
      setLoading(true);
    }
    if (status === SOCIAL_FEED_RETRIEVED) {
      setFeed(f => [...f, ...socialFeed]);
      setHasMore(socialFeed.length >= 15);
      setPage(p => p + 1);
      setLoading(false);
    }
    if (status === SOCIAL_FEED_FAILED) {
      setLoading(false);
    }
  }, [status, socialFeed]);

  const handleLoadMore = () => {
    const PAGE_SIZE = 15;

    dispatch(fetchFeed(page * PAGE_SIZE));
  };

  const isRowLoaded = ({ index }) => {
    return !hasMore || index < feed.length;
  };

  const rowRenderer = ({
    key,
    index,
    isScrolling,
    parent,
    isVisible,
    style
  }) => {
    const notification = feed[index];

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        key={key}
        parent={parent}
        rowIndex={index}
      >
        {({ measure }) => (
          <div style={style}>
            <div style={{ height: 120 }}>
              {NotificationFactory(notification, 'feed', undefined, {
                isScrolling,
                measure
              })}
            </div>
          </div>
        )}
      </CellMeasurer>
    );
  };

  return (
    <div className={classes.root}>
      <Typography variant="h6" color="primary">
        Novedades
      </Typography>
      {feed.length === 0 && loading && <img src={LoadingGif} alt="loading" />}
      <List className={classes.list}>
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={handleLoadMore}
          rowCount={feed.length + 1}
        >
          {({ onRowsRendered, registerChild }) => (
            <AutoSizer>
              {({ height, width }) => (
                <VList
                  ref={registerChild}
                  onRowsRendered={onRowsRendered}
                  deferredMeasurementCache={cache}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  rowCount={feed.length}
                  height={height}
                  width={width}
                />
              )}
            </AutoSizer>
          )}
        </InfiniteLoader>
      </List>
    </div>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    height: 'calc(100vh - 16px - 80px)',
    position: 'sticky',
    width: '300px',
    background: '#fdfbfc',
    borderRadius: '25px',
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    top: `calc(80px + ${theme.spacing(2)}px)`,

    display: 'flex',
    flexFlow: 'column',
    overflowY: 'auto'
  },
  feedContent: {
    display: 'flex',
    flexFlow: 'column'
  },
  list: {
    height: '100%'
  }
}));

export default Feed;
