import React from 'react';
import { Link } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { fade } from '@material-ui/core/styles/colorManipulator';
import {
  Typography,
  ListItem,
  ListItemAvatar,
  ListItemText
} from '@material-ui/core';
import RTVAvatar from '../RTVAvatar';

import { promotionTranslate } from '../../utils/discounts';

const styles = theme => ({
  primaryText: {
    divider: {
      borderBottomColor: fade(theme.palette.primary.main, 0.2)
    },
    fontWeight: 500
  },
  textLink: {
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
});

const MyPromotion = ({ notification, key, classes, type, noBorder }) => (
  <ListItem
    key={key}
    alignItems="flex-start"
    divider={!noBorder}
    classes={{
      divider: classes.divider
    }}
  >
    <ListItemAvatar>
      <Link to={`/vestidor/${notification.promotion.user.username}`}>
        <RTVAvatar
          src={
            notification.promotion.user.media
              ? notification.promotion.user.media.default_small ||
                notification.promotion.user.media.user_small
              : null
          }
          alt="avatar"
        />
      </Link>
    </ListItemAvatar>
    <ListItemText
      primary={
        <Typography className={classes.primaryText}>
          <Link
            to={`/vestidor/${notification.user.username}`}
            className={classes.textLink}
          >
            {notification.user.username}
          </Link>
        </Typography>
      }
      secondary={
        <>
          <Typography color="textPrimary">
            comenzó una nueva promoción{' '}
            {promotionTranslate(notification.promotion)} en su vestidor
          </Typography>
          {notification.timePassedString}
        </>
      }
    />
  </ListItem>
);

export default withStyles(styles)(MyPromotion);
