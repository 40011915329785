import {
  CREATE_DEVICES_REQUESTED,
  CREATE_DEVICES_SUCCESSFULL,
  CREATE_DEVICES_ERRORED,
  LOGOUT_REQUESTED
} from '../actions/types';

const defaultState = {
  info: null,
  processing: false
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case LOGOUT_REQUESTED: {
      return defaultState;
    }
    case CREATE_DEVICES_REQUESTED: {
      return {
        ...state,
        status: CREATE_DEVICES_REQUESTED,
        error: null,
        processing: true
      };
    }
    case CREATE_DEVICES_SUCCESSFULL: {
      return {
        ...state,
        status: CREATE_DEVICES_SUCCESSFULL,
        info: action.info,
        processing: false
      };
    }
    case CREATE_DEVICES_ERRORED: {
      return {
        ...state,
        status: CREATE_DEVICES_ERRORED,
        error: action.error,
        processing: false
      };
    }
    default:
      return state;
  }
};
